import React, { useState, useEffect } from "react";
import instance, { baseUrl } from "../../utils/axios";
import Contractecharts from "../../Components/Contractecharts";
import Linegraph from "../../Components/Linegraph";
import Ticketchart from "../../Components/Ticketchart";
import Unresolvedticketchart from "../../Components/Unresolvedticketchart";
import card1 from "../../Assests/images/totaltickets.svg";
import card2 from "../../Assests/images/opentickets.svg";
import card3 from "../../Assests/images/pendingtickets.svg";
import card4 from "../../Assests/images/closedtickets.svg";
import card5 from "../../Assests/images/resolvedticket.svg";
import card6 from "../../Assests/images/unresolvedticket.svg";
import { Link } from "react-router-dom";

import "./home.css";

function Index() {
  const [cardTicketData, setCardTicketData] = useState([]);
  const [ticketPriorityGraph, setTicketPriorityGraph] = useState([]);
  const [cardsData, setCardsData] = useState([
    {
      title: "Total Tickets",
      content: 0,
      color: "#E4E900",
      backgroundColor: "#FEFFDC",
      imageUrl: card1,
      state: "Total",
    },
    {
      title: "Open Tickets",
      content: 0,
      color: "#3ac785",
      imageUrl: card2,
      backgroundColor: "#EEFFD8",
      state: "Open",
    },
    {
      title: "Pending Tickets",
      content: 0,
      color: "#DA0404",
      backgroundColor: "#FFE5E5",
      imageUrl: card3,
      state: "Pending",
    },
    {
      title: "Closed Tickets",
      content: 0,
      color: "#68BB59",
      imageUrl: card4,
      backgroundColor: "#EAFFE7",
      state: "Closed",
    },
    {
      title: "Resolved Tickets",
      content: 0,
      color: "#1EB2F1",
      backgroundColor: "#E5F7FF",

      imageUrl: card5,
      state: "Resolved",
    },
    {
      title: "Unresolved Tickets",
      content: 0,
      color: "#E4E900",
      backgroundColor: "#FEFFD9",

      imageUrl: card6,
      state: "Waiting on customer",
    },
  ]);

  const getTicketStatusCard = async () => {
    try {
      const response = await instance.get(
        baseUrl + "/getAllTicketCountByStats"
      );

      setCardTicketData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getTicketPriorityGraph = async () => {
    try {
      const response = await instance.get(baseUrl + "/getTicketByMonth");
      setTicketPriorityGraph(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCardsData((prevData) =>
        prevData.map((card, index) => {
          const ticketData = cardTicketData[index];
          return {
            ...card,
            content: ticketData ? ticketData.value : 0,
          };
        })
      );
    }, 2000);

    return () => clearInterval(interval);
  }, [cardTicketData]);

  useEffect(() => {
    getTicketStatusCard();
    getTicketPriorityGraph();
  }, []);

  return (
    <div
    // style={{
    //   padding: "0px 30px 0px 30px",
    // }}
    >
      <div
        style={{
          // padding: "0px 30px 0px 0px",
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          minWidth: "93.5vw",
          marginTop: "30px",
          // flexBasis: "50%",
        }}
      >
        {cardsData.map((card, index) => (
          <div
            key={index}
            style={{
              width: "186.5px",
              position: "relative",
              marginBottom: "16px",
              // border: "2px solid red",
              height: "122px",
              borderRadius: "16px",
              // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.25)",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#ffffff",
              transition: "box-shadow 0.3s, background-color 0.3s",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.boxShadow = "0 0 8px rgba(0, 0, 0, 0.3)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.boxShadow = "0 0 4px rgba(0, 0, 0, 0.25)";
            }}
            // onClick={() => handleCardClick(card)}
          >
            <div
              style={{
                borderLeft:
                  index == "0"
                    ? "4px solid #E4E900"
                    : index == "1"
                    ? "4px solid #8BC73A"
                    : index == "2"
                    ? "4px solid #DA0404"
                    : index == "3"
                    ? "4px solid #68BB59"
                    : index == "4"
                    ? "4px solid #1EB2F1"
                    : "4px solid #FFD300",
                borderRadius: "4px 0 0 4px",
                width: "140px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  color: "#6C6C6C",
                  fontSize: "13px",
                  marginBottom: "8px",
                  justifyContent: "space-between",
                  // paddingTop: "20px",
                  // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Link
                  to="/tickets"
                  state={{
                    data: card.state,
                    stateData: card.title == "Total Tickets" ? "false" : "true",
                  }}
                  style={{
                    textDecoration: "none",
                    fontSize: "16px",
                    paddingLeft: "12px",
                    color: "#6C6C6C",
                    width: "50px",
                  }}
                >
                  <span>{card.title}</span>
                </Link>

                <div
                  style={{
                    backgroundColor: card.backgroundColor,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginLeft: "8px",
                    borderRadius: "6px",
                    // boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
                    padding: "5px",
                  }}
                >
                  <img
                    src={card.imageUrl}
                    alt="Image Alt Text"
                    style={{
                      width: "16px",
                      height: "16px",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  fontWeight: 700,
                  fontSize: "24px",
                  paddingLeft: "12px",
                  color: "#414142",
                }}
              >
                {card.content}
              </div>
            </div>
          </div>
        ))}

        <div style={{ padding: "18px 0px 0px 0px" }}>
          <div className="first-column">
            <div className="first-graph">
              <Contractecharts />
            </div>
            <div className="secound-graph">
              <Linegraph />
            </div>
          </div>
        </div>
        <div style={{ padding: "18px 0px 0px 0px" }}>
          <div className="first-column">
            <div className="third-graph">
              <Ticketchart />
            </div>
            <div className="fourth-graph">
              <Unresolvedticketchart />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
