import { Table, Tabs } from "antd";
import React from "react";
import "./Reports.css";
import AllTickets from "../Tickets/Index.jsx";
import MyTickets from "./mytickets/MyTickets.jsx";
import UserTickets from "./userTickets/UserTickets.jsx";
const Reports = () => {
  return (
    <>
      <div className="reports-container">
        <Tabs
          defaultActiveKey="reportAllTickets"
          items={[
            {
              label: `All Tickets`,
              key: "reportAllTickets",
            },
            {
              label: `My Tickets`,
              key: "reportMyTickets",
              children: <MyTickets />,
            },
            {
              label: `Users`,
              key: "reportUsersTickets",
              children: <UserTickets />,
            },
          ]}
        />
      </div>
    </>
  );
};

export default Reports;
