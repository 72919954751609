import styled from 'styled-components';
// import { TextField } from '@mui/material';
import {Col} from 'antd';
import Loginimg from "../../../Assests/images/Loginimg.png";

export const LoginStyled=styled.div`

/* border:"2px solid blue"; */
width: 100%;
height: 100vh;
background: rgba(255, 255, 255, 0.5);
`;
export const RightFlex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: #FFFFFF;
    
  
`;
export const ColStyled=styled(Col)` 
@media (max-width: 500px) {
display:none;
}
`;
export const LeftFlex=styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100vh;
background: #FFFFFF;
/* border:2px solid green; */
@media (max-width: 900px) {
    justify-content: center;
}
`;
export const LoginContainer=styled.div`
display: flex;
justify-content: center;
align-items: center;
width:75%;
height:100%;
background: #FFFFFF;
border-radius: 16px 0px 0px 16px;
/* border:2px solid black; */
@media (max-width: 500px) {
width: 100%;
height:100%;
padding-left: 10px;
padding-right: 10px;
}

`;
export const InnerContainer=styled.div`
display: flex;
flex-direction: column;
/* justify-content: center; */
width:75%;
height:80%;
/* border:2px solid blue; */
gap:20px;
@media (max-width: 900px) {
width:100%;
height:100%;
/* padding-right: 10px; */
}
/* @media (max-width: 700px) {
width: 100%;
height:100%;
} */
`;
export const Text=styled.p`

font-style: normal;
font-weight: 500;
font-size: 20px;
color: #000000;
letter-spacing: 0.04em;
`;
export const CheckText=styled.span`
font-family: 'Satoshi';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: #000000;
letter-spacing: 0.04em;
`;
export const CheckBox=styled.input`
width: 16px;
height: 16px;
background: #5C8246;
border-radius: 4px;
`;
export const LoginButton=styled.button`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top: 10%;
/* padding: 14px 146px; */
height: 50px;
width: 95%;
background:  #229849;
border-radius: 8px;
border:none;
font-style: normal;
font-weight: 500;
font-size: 20px;
color: #FFFFFF;
letter-spacing: 0.04em;
`;
export const InputContainer=styled.div`
display: flex;
flex-direction: column;
position: relative;
/* margin-top: 5%; */
`;

export const EyeIcon=styled.div`
position: absolute;
top: 29.35%;
bottom: 30.38%;
left: 90%;
right: 0;
`;
// export const TextInput=styled(TextField)` 
// padding:10px;
// width: 100%;
// height: 52px;
// background: #ffffff;
// border: 0.5px solid #c5c5c5;
// border-radius: 8px;
// color: #CCCCCC;
// letter-spacing: 0.03em;
// `;