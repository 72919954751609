import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { message, Modal, Form, Input, Spin, Select, Row, Col } from "antd";
import { Option } from "antd/es/mentions";
import { baseUrl } from "../../utils/axios";

import instance from "../../utils/axios";
import download from "../../Assests/images/download.png";

import "./contractDetail.css";
import backarrow from "./../../Assests/images/backarrow1.png";

function ContractDetail() {
  const [contract, setContract] = useState([]);
  const params = useParams();
  const contractDetail = contract && contract[0];

  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const dateString = contractDetail && contractDetail.start_date;
  const dateObject = new Date(dateString);
  const [isLoading, setIsLoading] = useState(false);
  const [approver, setApprover] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [visible, setVisible] = useState([]);

  const [errors, setErrors] = useState({});
  const Status = [{ value: "Active", label: "Active" }];

  const day = dateObject.getDate();
  const month = dateObject.toLocaleString("default", { month: "short" });
  const year = dateObject.getFullYear();

  let statusType;

  if (contractDetail && contractDetail.status == "Active") {
    statusType = "status-active";
  } else {
    statusType = "status-expired";
  }

  const handleDelete = async (id) => {
    await instance
      .delete(baseUrl + `/deletecontract/${id}`)
      .then((res) => {
        if (res.status == 200) {
          messageApi.open({
            type: "success",
            content: res.data.message,
          });
          setTimeout(() => {
            navigate("/contracts");
          }, 1000);
        }
      })
      .catch((error) => {
        if (error) {
          messageApi.open({
            type: "error",
            content: error.response.data.error
              ? error.response.data.error
              : error.message,
          });
        }

        // messageApi.open({
        //   type: "error",
        //   content: error.message,
        // });
      });
  };

  useEffect(() => {
    getContracts();
    getVendor();
    getVisible();
  }, []);

  const getVisible = async () => {
    try {
      const response = await instance.get(baseUrl + "/getallvisible");
      setVisible(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getVendor = async () => {
    try {
      const response = await instance.get(baseUrl + "/getallvendors");
      setVendor(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const getContracts = async () => {
    try {
      const response = await instance.get(baseUrl + "/getallcontract");
      if (response.status === 200) {
        const updatedData = response.data.filter(
          (data) => data.contract_id == params.id
        );
        const t = updatedData.map((item) => {
          return {
            ...item,
            vendor: vendor
              .map((data) =>
                data.vendor_name == item.vendor ? data.vendor_id : ""
              )
              .filter((groupId) => groupId !== "")[0],
          };
        });
        setContract(t);

        // setContract(populatedContracts);
      }
    } catch (error) {
      console.log("error======>", error);
    }
  };

  // For Edit functionality
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [type, setType] = useState([
    { value: "Lease", label: "Lease" },
    { value: "Maintenance", label: "Maintenance" },
    { value: "Software License", label: "Software License" },
    { value: "Warranty", label: "Warranty" }, // Add Warranty as a Type
  ]);
  const [editedContractDetail, setEditedContractDetail] = useState({
    contract_name: "",
    contract_number: "",
    type: "",
    status: "",
    renew_status: "",
    approver: "",
    vendor: "",
    cost: "",
    visible_to: "",
  });

  useEffect(() => {
    if (contractDetail) {
      setEditedContractDetail({ ...contractDetail });
    }
  }, [contractDetail]);
  useEffect(() => {
    getApprover();
  }, []);
  const getApprover = async () => {
    try {
      const response = await instance.get(baseUrl + "/getallapprover");
      setApprover(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase(); // Convert value to uppercase
    setEditedContractDetail({
      ...editedContractDetail,
      [name]: uppercaseValue,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };
  const handleSelectChange = (key, value) => {
    setEditedContractDetail((formData) => ({
      ...formData,
      [key]: value,
    }));
    // setApproverID(value);
    // console.log(label, "label");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const editContract = async (contract_id) => {
    try {
      setLoading(true);
      await instance
        .put(`${baseUrl}/editcontract/${contract_id}`, {
          contract_name: editedContractDetail.contract_name,
          contract_number: editedContractDetail.contract_number,
          type: editedContractDetail.type,
          status: editedContractDetail.status,
          // renew_status: editedContractDetail.renew_status,
          approver: editedContractDetail.approver,
          vendor: editedContractDetail.vendor,
          cost: editedContractDetail.cost,
          visible_to: editedContractDetail.visible_to,
        })
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          console.log("errrrorrrrr", err);
          setLoading(false);
        });

      // Close the modal
      setIsModalVisible(false);

      // Refresh contract details
      getContracts();

      // Show a success message
      messageApi.success("Contract details updated successfully");
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error("Error updating contract details:", error);
      messageApi.error("Error updating contract details");
    }
  };
  const contractLinkRef = useRef(null);

  const handleDownloadClick = () => {
    if (contractLinkRef.current) {
      contractLinkRef.current.click();
    }
  };
  return (
    <>
      {/* <Button className="go_back_btn" onClick={() => navigate("/contracts")}>
        <RollbackOutlined />
        <span>Back</span>
      </Button> */}
      {contextHolder}
      <Modal
        style={{
          top: 20,
        }}
        width={800}
        title="Edit Contract Details"
        visible={isModalVisible}
        onOk={() => editContract(contractDetail && contractDetail.contract_id)}
        onCancel={handleCancel}
        okButtonProps={{ className: "custom-ok-button" }}
        cancelButtonProps={{ className: "custom-cancel-button" }}
        okText="Update"
      >
        <Spin spinning={loading ? loading : null}>
          <Form>
            <Row>
              <Col xs={24} lg={12} style={{ padding: "10px" }}>
                <Form.Item>
                  <label className="label" htmlFor="approver">
                    Contract Name <span>*</span>
                  </label>
                  <Input
                    style={{
                      height: "40px",
                    }}
                    name="contract_name"
                    value={editedContractDetail.contract_name}
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12} style={{ padding: "10px" }}>
                <Form.Item>
                  <label className="label" htmlFor="approver">
                    Contract Number <span>*</span>
                  </label>
                  <Input
                    disabled
                    style={{
                      height: "40px",
                    }}
                    name="contract_number"
                    value={editedContractDetail.contract_number}
                    onChange={handleInputChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12} style={{ padding: "10px" }}>
                <Form.Item>
                  <div className="contract-input">
                    <label className="label" htmlFor="type">
                      Type <span>*</span>
                    </label>
                    <Select
                      value={editedContractDetail.type}
                      onChange={(value) => handleSelectChange("type", value)}
                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      {type.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12} style={{ padding: "10px" }}>
                <Form.Item>
                  <div className="contract-input">
                    <label className="label" htmlFor="status">
                      Status <span>*</span>
                    </label>
                    <Select
                      // mode="multiple"
                      placeholder=""
                      value={editedContractDetail.status}
                      onChange={(value) => handleSelectChange("status", value)}
                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      {Status.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12} style={{ padding: "10px" }}>
                <Form.Item>
                  <div className="contract-input">
                    <label className="label" htmlFor="visibleTo">
                      Visible To <span>*</span>
                    </label>
                    <Select
                      placeholder=""
                      value={editedContractDetail.visibles}
                      onChange={(value) =>
                        handleSelectChange("visible_to", value)
                      }
                      style={{
                        width: "100%",
                      }}
                      options={visible.map((visible) => ({
                        value: visible.visible_id,
                        label: visible.visible_name,
                      }))}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12} style={{ padding: "10px" }}>
                <Form.Item>
                  <div className="contract-input">
                    <label className="label" htmlFor="approver">
                      Approver <span>*</span>
                    </label>
                    {isLoading && (
                      <Spin
                        tip="Submitting..."
                        size="large"
                        style={{
                          position: "absolute",
                          top: "40%",
                          left: "50%",
                          transform: "translate(50%, 50%)",
                          color: "white",
                        }}
                      />
                    )}
                    <Select
                      name="approver"
                      // mode="multiple"
                      placeholder=""
                      value={
                        editedContractDetail.approver
                          ? editedContractDetail.approver
                          : editedContractDetail.approver_email
                      }
                      onChange={(label) =>
                        handleSelectChange("approver", label)
                      }
                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                      options={approver.map((approver) => ({
                        value: approver.approver_id,
                        label: approver.approver_name,
                      }))}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12} style={{ padding: "10px" }}>
                <Form.Item>
                  {/* <label className="label" htmlFor="approver">
                            Vendor <span>*</span>
                          </label>
                          <Input
                            style={{
                              height: "40px",
                            }}
                            name="vendor"
                            value={editedContractDetail.vendor}
                            onChange={handleInputChange}
                          /> */}
                  <div className="contract-input">
                    <label className="label" htmlFor="vendor">
                      Customer <span>*</span>
                    </label>
                    <Select
                      className="select"
                      placeholder=""
                      value={editedContractDetail.vendor}
                      onChange={(value) => handleSelectChange("vendor", value)}
                      style={{
                        width: "100%",
                      }}
                      options={vendor.map((vendor) => ({
                        value: vendor.vendor_id,
                        label: vendor.vendor_name,
                      }))}
                    />
                  </div>
                </Form.Item>
              </Col>
              <Col xs={24} lg={12} style={{ padding: "10px" }}>
                <Form.Item>
                  {/* <label className="label" htmlFor="approver">
                            Cost <span>*</span>
                          </label>
                          <Input
                            style={{
                              height: "40px",
                            }}
                            name="cost"
                            value={editedContractDetail.cost}
                            onChange={handleInputChange}
                          /> */}
                  <div className="contract-input">
                    <label className="label" htmlFor="cost">
                      Cost <span>*</span>
                    </label>
                    <Input
                      type="number"
                      id="cost"
                      name="cost"
                      value={editedContractDetail.cost}
                      onChange={handleInputChange}
                      required
                    />

                    {errors.cost && (
                      <span className="error">{errors.cost}</span>
                    )}
                  </div>
                </Form.Item>
              </Col>
            </Row>

            {/* Add more form input fields for other contract details */}
          </Form>
        </Spin>
      </Modal>
      <div style={{ width: "90vw" }}>
        <Link
          className="back_btn_link"
          style={{ textDecoration: "none", color: "#6B6B6B !important" }}
          to="/contracts"
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "15px",
                marginLeft: "30px",
              }}
            >
              <img src={backarrow} height={15} width={15} />

              <h2 style={{ paddingLeft: "15px", margin: 0 }}>
                {contractDetail && contractDetail.contract_name}
              </h2>
            </div>
          </div>
        </Link>

        <section className="contract-details-wrapper">
          <article className="section-header">
            <h3 className="title">HBL SmartNet Dec</h3>
            {localStorage.getItem("role_id") !== "User" && (
              <article className="action-buttons-wrapper">
                <button
                  className=""
                  onClick={showModal}
                  style={{ color: "#229849", border: "1px solid#229849" }}
                >
                  Edit
                </button>
                {/* <button className="terminate-btn">Teminate</button> */}
                <button
                  className="delete-btn"
                  onClick={() =>
                    handleDelete(contractDetail && contractDetail.contract_id)
                  }
                >
                  Delete
                </button>
              </article>
            )}
          </article>
          <article className="contract-details-body">
            <article className="contractor-details">
              <article className="contract-item-list">
                <article className="contract-item">
                  <h2 className="heading">Contract Number</h2>
                  <h2 className="value">
                    {contractDetail && contractDetail.contract_number}
                  </h2>
                </article>

                <article className="contract-item">
                  <h2 className="heading">Type</h2>
                  <h2 className="value">
                    {contractDetail && contractDetail.type}
                  </h2>
                </article>
                <article className="contract-item">
                  <h2 className="heading">Status</h2>
                  <span className={`capsule ${statusType}`}>
                    {contractDetail && contractDetail.status}
                  </span>
                </article>
                <article className="contract-item">
                  <h2 className="heading">Contract Name</h2>
                  <h2 className="value">
                    {contractDetail && contractDetail.contract_name}
                  </h2>
                </article>
                <article className="contract-item">
                  <h2 className="heading">Visible To</h2>
                  <h2 className="value">
                    {contractDetail && contractDetail.visible_to}
                  </h2>
                </article>
                <article className="contract-item">
                  <h2 className="heading">Approver</h2>
                  <h2 className="value">
                    {contractDetail && contractDetail.approver_email}
                  </h2>
                </article>
                <article className="contract-item">
                  <h2 className="heading">Cost</h2>
                  <h2 className="value">
                    {contractDetail && contractDetail.cost} AED
                  </h2>
                </article>
                <article className="contract-item">
                  <h2 className="heading">Renwal Status</h2>
                  <span className="capsule-light">
                    {contractDetail && contractDetail.renew_status}
                  </span>
                </article>
                <article className="contract-item">
                  <h2 className="heading">Due Date</h2>
                  <h2 className="value">
                    {contractDetail && contractDetail.end_date}
                  </h2>
                </article>
              </article>

              <article className="attachments-details">
                <h2 className="heading">Attachments</h2>

                <article className="icons-wrapper">
                  <div> {contractDetail && contractDetail.attachment_path}</div>
                  {/* <picture className="icon">
                <p>{contractDetail && contractDetail.attachment_info}</p>
              </picture> */}
                  {/* <picture className="icon">
                <img src={file} alt="File Icon" />
              </picture> */}
                  <div>
                    <a
                      ref={contractLinkRef}
                      href={contractDetail && contractDetail.file_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ display: "none" }} // Hide the link
                    >
                      {contractDetail && contractDetail.file_url}
                    </a>
                    <div className="icon">
                      <img
                        src={download}
                        alt="File Icon"
                        width={30}
                        height={30}
                        onClick={handleDownloadClick}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </article>
              </article>
            </article>

            <article className="contractor-status">
              <article className="status-details">
                <h2 className="heading">Status</h2>
                <span className={`capsule ${statusType}`}>
                  {contractDetail && contractDetail.status}
                </span>
              </article>
              <article className="date">
                <h2 className="heading">Start Date</h2>
                <h2 className="value">
                  {" "}
                  {contractDetail && contractDetail.start_date}
                </h2>
              </article>
              <article className="date">
                <h2 className="heading">End Date</h2>
                <h2 className="value">
                  {contractDetail && contractDetail.end_date}
                </h2>
              </article>
              <article className="email-list">
                <h2 className="heading">To:</h2>
                <h2 className="email">
                  {contractDetail && contractDetail.approver_email}
                </h2>
                <h2 className="email">
                  {contractDetail && contractDetail.vendor_email}
                </h2>
              </article>
            </article>
          </article>
        </section>
      </div>
    </>
  );
}

export default ContractDetail;
