import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl, header } from "../../utils/axios";
import { Col, message, Row, Spin } from "antd";
import InvoiceLogo from "../../Assests/images/new logo.svg";
import Logo from "../../Assests/images/login-img.svg";
import Eye from "./../../Assests/images/eye.svg";
import EyeShow from "../../Assests/images/eye_close.svg";
import Loginimg from "../../Assests/images/Loginimg.svg";
import { Image } from "antd";
import {
  ColStyled,
  EyeIcon,
  InnerContainer,
  InputContainer,
  LeftFlex,
  LoginButton,
  LoginContainer,
  LoginStyled,
  RightFlex,
  Text,
} from "./styles/main.styles";
import "./login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState("password");
  const [image, setImage] = useState(EyeShow);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  let navigate = useNavigate();

  const getShow = (e) => {
    if (e === "password") {
      setImage(Eye);
      return "text";
    } else {
      setImage(EyeShow);
      return "password";
    }
  };

  const postData = async (e) => {
    e.preventDefault();
    setLoading(true);
    let data = { user: email, pass: password };

    try {
      const response = await axios.post(baseUrl + "/login", data, header);

      localStorage.setItem("auth_key", response.data["auth-key"]);
      localStorage.setItem("id", response.data.id);
      localStorage.setItem("name", response.data.name);
      localStorage.setItem("role", response.data.role);
      localStorage.setItem("role_id", response.data.role);

      if (response && response.data.role.toLowerCase() === "user") {
        // If the role is "user", navigate to "/tickets"
        navigate("/tickets");
      } else {
        messageApi.open({
          type: "success",
          content: response.data.message,
        });
        setLoading(false);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      if (error && error.response) {
        const statusCode = error.response.data.status;
        const errorMessage = error.response.data.message;

        if (statusCode === 501 || statusCode === 502) {
          messageApi.open({
            type: "error",
            content: errorMessage,
          });
        } else if (statusCode === 401) {
          // Password incorrect error
          messageApi.open({
            type: "error",
            content: "Your password is incorrect",
          });
        } else {
          messageApi.open({
            type: "error",
            content: errorMessage || error.message,
          });
        }
      } else {
        messageApi.open({
          type: "error",
          content: error.message || "An error occurred",
        });
      }
    }
  };

  return (
    <Spin spinning={loading} size="large">
      {contextHolder}
      <LoginStyled>
        <Row>
          <Col xl={12} lg={12} md={12} xs={24} sm={24}>
            <LeftFlex>
              <LoginContainer>
                <InnerContainer>
                  <img src={InvoiceLogo} width="200px" height="130px" />
                  <Text>Welcome. Please login to your account</Text>
                  <form
                    onSubmit={postData}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "35px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "38px",
                      }}
                    >
                      <div className="input-field">
                        <input
                          type="email"
                          name="email"
                          value={email}
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label>Email</label>
                      </div>
                      <div className="input-field">
                        <input
                          type={show}
                          name="password"
                          value={password}
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <label style={{ width: "90px" }}>Password</label>
                        <img
                          style={{ right: "40px" }}
                          src={image}
                          height="20px"
                          width="20px"
                          onClick={(e) => setShow(getShow(show))}
                        />
                      </div>
                    </div>
                    <LoginButton type="submit" onClick={postData}>
                      Login
                    </LoginButton>
                  </form>
                </InnerContainer>
              </LoginContainer>
            </LeftFlex>
          </Col>
          <ColStyled xl={12} lg={12} md={12} xs={0} sm={0}>
            <RightFlex>
              <Image src={Loginimg} width={470} height={470} preview={false} />
            </RightFlex>
          </ColStyled>
        </Row>
      </LoginStyled>
    </Spin>
  );
};

export default Login;
