import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react"; // Import ReactECharts
import axios from "axios";
import { baseUrl } from "../utils/axios";
import "./Linegraph.css";
import instance from "../utils/axios";

function Linegraph() {
  const [ticketPriorityGraph, setTicketPriorityGraph] = useState([]);
  // API
  const getTicketPriorityGraph = async () => {
    try {
      const response = await instance.get(baseUrl + "/getTicketByMonth");
      // Sort the data by month
      const sortedData = response.data.sort((a, b) => {
        const monthA = new Date(a.month).getMonth();
        const monthB = new Date(b.month).getMonth();
        return monthA - monthB;
      });
      setTicketPriorityGraph(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getTicketPriorityGraph();
  }, []);

  // Define the options for the ECharts chart
  const options = {
    title: {
      text: "Number of tickets By Priority",
      textStyle: {
        color: "#6C6C6C", // Set the title font color to #6C6C6C
        fontSize: "16px",
        fontWeight: 400,
      },
    },
    tooltip: {
      trigger: "axis",
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: ticketPriorityGraph.map((item) => {
        const fullMonthName = new Date(item.month).toLocaleString("default", {
          month: "long",
        });
        return fullMonthName.slice(0, 3); // Extract the first 3 letters
      }),
      axisLabel: {
        color: "#000000",
        fontSize: 13,
        fontWeight: 500,
        margin: 15,
      },
    },

    yAxis: [
      {
        type: "value",
        position: "right", // Set the position to right
        axisLabel: {
          color: "#000000", // Set font color to black
          fontSize: 16, // Set font size to 16px
          fontWeight: 500, // Set font weight to 500
        },
      },
    ],
    series: [
      {
        name: "high_priority_count",
        type: "line",
        stack: "Total",
        data: ticketPriorityGraph.map((item) => item.high_priority_count),
      },
      {
        name: "urgent_priority_count",
        type: "line",
        stack: "Total",
        data: ticketPriorityGraph.map((item) => item.urgent_priority_count),
      },
      {
        name: "medium_priority_count",
        type: "line",
        stack: "Total",
        data: ticketPriorityGraph.map((item) => item.medium_priority_count),
      },
      {
        name: "low_priority_count",
        type: "line",
        stack: "Total",
        data: ticketPriorityGraph.map((item) => item.low_priority_count),
      },
    ],
  };

  return (
    <div
      className="Line-graph"
      style={{ width: "100%", maxWidth: "100%", paddingTop: "20px" }}
    >
      {/* Use the ReactECharts component to render the chart */}
      <ReactECharts option={options} style={{ height: "330px" }} />
    </div>
  );
}

export default Linegraph;
