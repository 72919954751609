import React, { createContext, useState } from "react";
import axios from "axios";
import { baseUrl } from "./utils/axios";
import { useEffect } from "react";
import instance from "./utils/axios";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [contact, setContact] = useState([]);
  const [contract, setContract] = useState([]);
  const [group, setGroup] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [agent, setagent] = useState([]);
  const [ticketData, setTicketData] = useState({
    contact: "",
    subject: "",
    source: "",
    status: "",
    urgency: "",
    impact: "",
    priority: "",
    group: "",
    contract: "",
    description: "",
    custom_ticket_type: "",
    email: "",
    product: "",
    agent: "",
    onsite_day: "",
    onsite_time: "",
    ticket_close_date: "",
  });

  const mainKey = localStorage.getItem("auth_key");

  useEffect(() => {
    if (mainKey) {
      // Call your API functions here
      getAllContacts();
      getAllCompanies();
      getAllGroups();
      getAllAgents();
      getAllCustomers();
      getContracts();
    }
  }, [mainKey]);

  const getAllContacts = async () => {
    try {
      const response = await instance.get(baseUrl + "/getallvendors");
      setContact(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAllCompanies = async () => {
    try {
      const response = await instance.get(baseUrl + "/getAllActiveContracts");

      setContract(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAllGroups = async () => {
    try {
      const response = await instance.get(baseUrl + "/getAllGroups");
      setGroup(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAllAgents = async () => {
    try {
      const response = await instance.get(baseUrl + "/getallusers");
      setagent(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getAllCustomers = async () => {
    instance
      .get(`${baseUrl}/getallvendorsdetails`)
      .then((res) => {
        setVendors(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getContracts = async () => {
    await instance
      .get(baseUrl + "/getallcontract")
      .then((res) => {})
      .catch((error) => {
        console.log("error", error);
      });
  };

  // dropdown get for Option
  //   const { contact,company,group,agent } = useContext(AppContext);

  const [impact, setImpact] = useState([
    { value: "Low", label: "Low" },
    { value: "Medium", label: "Medium" },
    { value: "High", label: "High" },
  ]);

  const [category, setCategory] = useState([
    { value: "software", label: "Software" },
    { value: "network", label: "Network" },
    { value: "hardware", label: "Hardware" },
  ]);

  const [priority, setPriority] = useState([
    { value: "Low", label: "Low" },
    { value: "Medium", label: "Medium" },
    { value: "High", label: "High" },
    { value: "Urgent", label: "Urgent" },
  ]);

  const [status, setStatus] = useState([
    { value: "Open", label: "Open" },
    { value: "Pending", label: "Pending" },
    { value: "Resolved", label: "Resolved" },
    { value: "Closed", label: "Closed" },
    { value: "Scheduled", label: "Scheduled" },
    { value: "RMA", label: "RMA" },
    { value: "Waiting on customer", label: "Waiting on Customer" },
    { value: "Waiting on vendor", label: "Waiting on Vendor" },
    { value: "Recorded", label: "Recorded" },
  ]);

  const [source, setSource] = useState([
    { value: "Phone", label: "Phone" },
    { value: "Email", label: "Email" },
  ]);

  const handleSelectChange = (key, value) => {
    setTicketData((ticketData) => ({
      ...ticketData,
      [key]: value,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setTicketData({
      ...ticketData,
      [name]: value,
    });
  };

  // You can define functions to update the state here

  return (
    <AppContext.Provider
      value={{
        contact,
        contract,
        group,
        agent,
        impact,
        source,
        status,
        priority,
        category,
        vendors,
        handleInputChange,
        handleSelectChange,
        getAllContacts,
        getAllCompanies,
        getAllGroups,
        getAllAgents,
        getAllCustomers,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
