import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { baseUrl } from "./../../utils/axios/index";
import { Link, useNavigate } from "react-router-dom";
import instance from "./../../utils/axios/index";
import customer from "../../Assests/images/customer.png";
import { Input, Button, message, Spin, Row, Col } from "antd";
import { Image } from "antd";
import AppContext from "./../../AppContext";
import "./addvendor.css";
import PhoneInput from "react-phone-input-2";

const Index = () => {
  const { getAllContacts } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(false);
  const [countryError, setCountryError] = useState("");
  const [addVendor, setAddVendor] = useState({
    name: "",
    phone: "",
    mobile: "",
    email: "",
    address: "",
    description: "",
    country: "",
    state: "",
    zip_code: "",
  });

  const navigate = useNavigate();

  const handleSubmitTicket = async (e) => {
    e.preventDefault();

    const requiredFields = ["name", "phone", "country", "email", "description"];

    const missingFields = requiredFields.filter((field) => !addVendor[field]);

    const replaceName = missingFields.map((field) => {
      if (field == "name") {
        return "customer name";
      }
      return field;
    });

    if (replaceName.length > 0) {
      message.error(`${replaceName.join(", ")} field is required.`);
      return;
    }

    if (emailError) {
      message.error("Please correct the email field before submitting.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await instance.post(`${baseUrl}/addVendor`, addVendor);

      if (response.status === 200) {
        try {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          message.success("Customer is successfully Added");
          setIsLoading(false);
          navigate("/customers");

          setAddVendor({
            name: "",
            phone: "",
            mobile: "",
            email: "",
            address: "",
            description: "",
            country: "",
            state: "",
          });
        } catch (error) {
          console.error("Error submitting ticket:", error);
        }
      } else {
        // Handle the case when the API call is successful (status code not 200, but response may contain an error message)
        message.error("Failed to create the customer. Please try again.");
        setIsLoading(false);
      }
    } catch (error) {
      message.error(error && error.response.data);

      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      // const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      if (!value.trim()) {
        setEmailError("Email is required");
      }
      // else if (!emailPattern.test(value)) {
      //   setEmailError("Invalid email address");
      // }
      else if (value.includes("@example.com")) {
        setEmailError('Email cannot contain "@example.com"');
      }
      // else if (value.split("@")[1].split(".").length > 2) {
      //   setEmailError("Invalid email format");
      // }
      else if (value.includes("..")) {
        setEmailError("Email cannot contain two consecutive dots '..'");
      } else {
        setEmailError("");
      }
    } else if (name === "phone" || name === "mobile") {
      // Regular expression to validate phone numbers in multiple formats
      const phoneRegex = /^(?:(?:\+|00)(92|971|966|44)|0)([1-9]\d{9}|[1-9]\d{8}|[1-9]\d{7}|[1-9]\d{10})$/;

      if (!value.trim()) {
        setPhoneError("Phone number is required");
      }
      // else if (!phoneRegex.test(value)) {
      //   setPhoneError("Invalid phone number format");
      // }
      else {
        setPhoneError("");
      }
    } else if (name === "country") {
      if (!value.trim()) {
        setCountryError("Country is required");
      } else {
        setCountryError("");
      }
    }

    setAddVendor({
      ...addVendor,
      [name]: value,
    });
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b\w/g, function(char) {
      return char.toUpperCase();
    });
  };

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  useEffect(() => {
    getAllContacts();
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmitTicket}
        style={{
          flexBasis: "95%",
          padding: "0px 0px 0px 50px",
        }}
      >
        <div style={{ flexBasis: "100%", marginBottom: "20px" }}>
          <Row gutter={16} align="middle">
            <Col xs={24} sm={12}>
              <div
                className="newticket"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <div>
                  <Image
                    src={customer}
                    preview={false}
                    width={25}
                    height={25}
                  />
                </div>
                <p
                  style={{
                    fontSize: "20px",
                    fontWeight: 500,
                    marginBottom: "0px",
                  }}
                >
                  Add Customer
                </p>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div
                className="newticket-button"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <Button
                  className="save-btn"
                  type="submit"
                  onClick={handleSubmitTicket}
                  disabled={
                    isLoading || phoneError !== "" || countryError !== ""
                  }
                  style={{
                    backgroundColor: "#229849",
                    color: "white",
                    opacity: isLoading || phoneError !== "" ? 2 : 1, // Reduce opacity when disabled
                    filter:
                      isLoading || phoneError !== "" ? "blur(1.5px)" : "none", // Apply a blur filter
                  }}
                >
                  {isLoading ? "Creating..." : "Create"}
                </Button>
                <Link to="/customers">
                  <Button className="cancel-btn" type="submit">
                    Cancel
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="type">
                  Customer Name<span>*</span>
                </label>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  className="custom-input"
                  value={addVendor.name}
                  onChange={(e) => {
                    setAddVendor({
                      ...addVendor,
                      name: capitalizeWords(e.target.value),
                    });
                  }}
                />
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="contractNumber">
                  Phone <span>*</span>
                </label>
                <PhoneInput
                  country="ae"
                  enableSearch={true}
                  value={addVendor.phone}
                  className="phone-input"
                  excludeCountries={["il"]}
                  onChange={(data) =>
                    setAddVendor({
                      ...addVendor,
                      phone: data,
                    })
                  }
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                />
                {/* <Input
                  type="text"
                  id="phone"
                  name="phone"
                  value={addVendor.phone}
                  onChange={handleInputChange}
                  pattern="^[A-Za-z\s+]+$"
                  placeholder="+92"
                /> */}
                {phoneError && (
                  <div className="error" style={{ color: "red" }}>
                    {phoneError}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="contractNumber">
                  Mobile
                </label>
                <PhoneInput
                  country="ae"
                  enableSearch={true}
                  value={addVendor.mobile}
                  className="phone-input"
                  excludeCountries={["il"]}
                  onChange={(data) =>
                    setAddVendor({
                      ...addVendor,
                      mobile: data,
                    })
                  }
                  inputProps={{
                    name: "phone",
                    required: true,
                    autoFocus: true,
                  }}
                />
                {/* <Input
                  type="text"
                  id="mobile"
                  name="mobile"
                  value={addVendor.mobile}
                  onChange={handleInputChange}
                  pattern="^[A-Za-z\s+]+$"
                  placeholder="+92"
                /> */}
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="email">
                  Email <span>*</span>
                </label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  value={addVendor.email}
                  onChange={handleInputChange}
                />
                {emailError && (
                  <div className="error" style={{ color: "red" }}>
                    {emailError}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="contractNumber">
                  Country <span>*</span>
                </label>
                <Input
                  type="text"
                  id="country"
                  name="country"
                  value={addVendor.country}
                  onChange={handleInputChange}
                />
                {countryError && (
                  <div className="error" style={{ color: "red" }}>
                    {countryError}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="contractNumber">
                  State
                </label>
                <Input
                  type="text"
                  id="state"
                  name="state"
                  value={addVendor.state}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: "20px" }} gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input" style={{ position: "relative" }}>
                {isLoading && (
                  <Spin
                    tip="Submitting..."
                    size="large"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      zIndex: 1, // Ensure the loader is above other elements
                    }}
                  />
                )}

                <label className="label" htmlFor="contractNumber">
                  Address
                </label>
                <Input
                  type="text"
                  id="address"
                  name="address"
                  value={addVendor.address}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>

          <div
            className="description"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label className="label" htmlFor="contractNumber">
              Description <span>*</span>
            </label>
            <textarea
              rows={10}
              columns={10}
              style={{
                border: "1px solid #d9d9d9",
                borderRadius: "6px",
                marginTop: "10px",
                padding: "10px",
              }}
              placeholder=""
              type="text"
              id="description"
              name="description"
              value={addVendor.description}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default Index;
