import React from "react";
import { useEffect } from "react";
import { baseUrl } from "./../../utils/axios/index";
import { Link, useNavigate } from "react-router-dom";
import instance from "./../../utils/axios/index";
import { Input, Button, message, Row, Col } from "antd";
import { Select, Image } from "antd";
import { useState } from "react";
import newticket from "./../../Assests/images/newticket.png";
import "./adduser.css";

const Index = () => {
  //   const { contact, getAllContacts } = useContext(AppContext);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [addUser, setAddUser] = useState({
    name: "",
    email: "",
    role: "",
    password: "",
  });

  const navigate = useNavigate();

  const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~'"\\/-1234567890]/;

  const handleSubmitTicket = async (e) => {
    e.preventDefault();

    const requiredFields = ["name", "email", "role", "password"];

    const missingFields = requiredFields.filter((field) => !addUser[field]);

    if (missingFields.length > 0) {
      message.error(`${missingFields.join(", ")} field is required.`);
      return;
    }

    if (emailError) {
      message.error("Please correct the email field before submitting.");
      return;
    }

    setIsLoading(true);

    try {
      const response = await instance.post(`${baseUrl}/createuser`, addUser);

      if (response.status === 200) {
        try {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          message.success("User is successfully Added");
          setIsLoading(false);
          setShowSuccessMessage(true);
          // Reset the form data after submission
          setAddUser({
            name: "",
            email: "",
            role: "",
            password: "",
          });

          navigate("/users");
        } catch (error) {
          message.error(error);
          console.error("Error submitting ticket:", error);
        }
      } else {
        // Handle the case when the API call is successful (status code not 200, but response may contain an error message)
        message.error("Failed to create the customer. Please try again.");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("API Error:", error);
      message.error(error.response.data.response);
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      if (!value.trim()) {
        setEmailError("Email is required");
      } else if (!emailPattern.test(value)) {
        setEmailError("Invalid email address");
      } else if (value.includes("@example.com")) {
        setEmailError('Email cannot contain "@example.com"');
      } else if (value.split("@")[1].split(".").length > 2) {
        setEmailError("Invalid email format");
      } else if (value.includes("..")) {
        setEmailError("Email cannot contain two consecutive dots '..'");
      } else {
        setEmailError("");
      }
    } else if (name === "phone") {
      // Regular expression to validate phone numbers in multiple formats
      const phoneRegex = /^(?:(?:\+|00)(92|971|966|44)|0)([1-9]\d{9}|[1-9]\d{8}|[1-9]\d{7}|[1-9]\d{10})$/;

      if (!value.trim()) {
        setPhoneError("Phone number is required");
      }
      // else if (!phoneRegex.test(value)) {
      //   setPhoneError("Invalid phone number format");
      // }
      else {
        setPhoneError("");
      }
    } else if (name === "name") {
      if (!value.trim()) {
        setNameError("Name is required");
      } else if (specialCharRegex.test(value)) {
        setNameError("Name cannot contain special characters");
      } else {
        setNameError("");
      }
    } else if (name === "country") {
      if (!value.trim()) {
        setCountryError("Country is required");
      } else if (!/^[A-Za-z]+$/.test(value)) {
        setCountryError("Country is not valid");
      } else {
        setCountryError("");
      }
    } else if (name === "mobile") {
      // Regular expression to validate phone numbers in multiple formats
      const phoneRegex = /^(?:(?:\+|00)(92|971|966|44)|0)([1-9]\d{9}|[1-9]\d{8}|[1-9]\d{7}|[1-9]\d{10})$/;

      if (!value.trim()) {
        setMobileError("Phone number is required");
      } else if (!phoneRegex.test(value)) {
        setMobileError("Invalid mobile number");
      } else {
        setMobileError("");
      }
    }

    setAddUser({
      ...addUser,
      [name]: value,
    });
  };

  // ]);

  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const handleSelectChange = (key, value) => {
    setAddUser((addUser) => ({
      ...addUser,
      [key]: value,
    }));
  };

  const [role, setRole] = useState([]);

  const getAllRole = async () => {
    try {
      const response = await instance.get(baseUrl + "/getUserRoleDropdown");
      setRole(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getAllRole();
  }, []);

  return (
    <>
      <form
        onSubmit={handleSubmitTicket}
        style={{
          flexBasis: "95%",
          padding: "0px 0px 0px 23px",
        }}
      >
        <div style={{ flexBasis: "100%" }}>
          <Row gutter={16} align="middle">
            <Col xs={24} sm={12}>
              <div
                className="newticket"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "20px",
                }}
              >
                <Image src={newticket} preview={false} width={25} height={20} />
                <h3 style={{ margin: 0 }}>Add User</h3>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div
                className="newticket-button"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <Button
                  className="save-btn"
                  type="submit"
                  onClick={handleSubmitTicket}
                  disabled={
                    isLoading ||
                    phoneError !== "" ||
                    countryError !== "" ||
                    nameError != ""
                  }
                  style={{
                    backgroundColor: "#229849",
                    color: "white",
                    opacity: isLoading || phoneError !== "" ? 2 : 1, // Reduce opacity when disabled
                    filter:
                      isLoading || phoneError !== "" ? "blur(1.5px)" : "none", // Apply a blur filter
                  }}
                >
                  {isLoading ? "Saving..." : "Save"}
                </Button>
                <Link to="/users">
                  <Button className="cancel-btn" type="submit">
                    Cancel
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="contractNumber">
                  User Name <span>*</span>
                </label>

                <Input
                  placeholder=""
                  // type="text"
                  id="name"
                  name="name"
                  value={addUser.name}
                  onChange={handleInputChange}
                  pattern="^[A-Za-z\s]+$"
                />
                {nameError && <div style={{ color: "red" }}>{nameError}</div>}
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="email">
                  Email <span>*</span>
                </label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  value={addUser.email}
                  onChange={handleInputChange}
                />
                {emailError && (
                  <div className="error" style={{ color: "red" }}>
                    {emailError}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="type">
                  Role <span>*</span>
                </label>
                <Select
                  //   value={addVendor.contact}
                  onChange={(value) => handleSelectChange("role", value)}
                  style={{
                    width: "100%",
                    marginTop: "8px",
                  }}
                  options={role.map((role) => ({
                    value: role,
                    label: role,
                  }))}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="contractNumber">
                  Password <span>*</span>
                </label>
                <Input
                  // type="password"
                  id="password"
                  name="password"
                  value={addUser.password}
                  onChange={handleInputChange}
                  //   onKeyPress={handleKeyPress}
                />
                {mobileError && (
                  <div className="error" style={{ color: "red" }}>
                    {mobileError}
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}></Row>
        </div>
      </form>
    </>
  );
};

export default Index;
