import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../utils/axios";
import instance from "../../utils/axios";
import { Table, Button, Input, Select, Upload, Spin, Modal } from "antd";
import "./Contracts.css";
import { Link } from "react-router-dom";

import { Option } from "antd/es/mentions";

// import { SearchOutlined } from "@ant-design/icons";
import {
  SearchOutlined,
  DownloadOutlined,
  ExportOutlined,
  InboxOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Swal from "sweetalert2";

import { message } from "antd";
import moment from "moment";
import dayjs from "dayjs";

const { Dragger } = Upload;

function Index() {
  const [contract, setContract] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [open2, setOpen2] = useState(false);
  const [fileList, setFileList] = useState([]);

  const navigate = useNavigate();

  const getContracts = async () => {
    const res = await instance.get(baseUrl + "/getallcontract");
    setLoading(true);

    if (res.status == 200) {
      const formattedContracts = res.data.map((contract) => ({
        ...contract,
        start_date: moment(contract.start_date).format("YYYY-MM-DD"),
        end_date: moment(contract.end_date).format("YYYY-MM-DD"),
      }));

      // Update the state with the formatted contracts
      setContract(formattedContracts);
      setLoading(false);
    } else {
      setContract([]);
      setLoading(false);
      messageApi.open({
        type: "error",
        content: res.data.message,
      });
    }
    // setPieGraph(res.data);
  };

  useEffect(() => {
    getContracts();
  }, []);

  const LoadingIndicator = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <LoadingOutlined
        style={{
          fontSize: 24,
          color: "#229849",
        }}
        spin
      />
    </div>
  );

  const columns = [
    {
      title: "ID",
      dataIndex: "contract_id",
      key: "contract_id",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.contract_id.localeCompare(b.contract_id),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Contract ID"
            className="filter_input"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.contract_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (text) => (
        <span title={text}>
          {text.length > 30 ? text.slice(0, 30) + "..." : text}
        </span>
      ),
    },

    {
      title: "Contract Name",
      dataIndex: "contract_name",
      key: "contract_name",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.contract_name.localeCompare(b.contract_name),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Contract Name"
            className="filter_input"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.contract_name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (text) => (
        <span title={text}>
          {text.length > 30 ? text.slice(0, 30) + "..." : text}
        </span>
      ),
    },
    {
      title: "Contract Number",
      dataIndex: "contract_number",
      key: "contract_number",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.contract_number.localeCompare(b.contract_number),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Contract Number"
            className="filter_input"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.contract_number
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (text) => (
        <span title={text}>
          {text.length > 30 ? text.slice(0, 30) + "..." : text}
        </span>
      ),
    },
    {
      title: "Customer",
      dataIndex: "vendor",
      key: "vendor",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.vendor.localeCompare(b.vendor),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        // Check if the value is empty, and if so, do not apply the filter
        if (value === "") {
          return true; // Show all records when the filter is empty
        }
        return record.vendor.toLowerCase().startsWith(value.toLowerCase());
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Vendor"
            className="filter_input"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
    },
    {
      title: "Vendor",
      dataIndex: "add_vendor",
      key: "add_vendor",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.add_vendor.localeCompare(b.add_vendor),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        // Check if the value is empty, and if so, do not apply the filter
        if (value === "") {
          return true; // Show all records when the filter is empty
        }
        return record.add_vendor.toLowerCase().startsWith(value.toLowerCase());
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Vendor"
            className="filter_input"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
    },
    {
      title: "Customer Email",
      dataIndex: "vendor_email",
      key: "vendor_email",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.vendor_email.localeCompare(b.vendor_email),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        // Check if the value is empty, and if so, do not apply the filter
        if (value === "") {
          return true; // Show all records when the filter is empty
        }
        return record.vendor_email
          .toLowerCase()
          .startsWith(value.toLowerCase());
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Customer Email" // Vendor Email to Customer Email
            className="filter_input"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.type.localeCompare(b.type),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Select
            mode="multiple"
            placeholder="Select Type"
            value={selectedKeys}
            onChange={(value) => setSelectedKeys(value)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          >
            <Option value="Lease">Lease </Option>
            <Option value="Maintenance">Maintenance</Option>
            <Option value="Software License">Software License</Option>
            <Option value="Warranty">Warranty</Option>
            {/* Add more type options as needed */}
          </Select>
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Filter
          </Button>
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#76BA1B" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.type.includes(value);
      },
    },
    {
      title: "Approver",
      dataIndex: "approver_email",
      key: "approver_email",
      // defaultSortOrder: "ascend",
      // sorter: (a, b) => a.approver_email.localeCompare(b.approver_email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Approver"
            className="filter_input"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.approver_email
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "Auto Renew",
      dataIndex: "auto_renew",
      key: "auto_renew",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.auto_renew.localeCompare(b.auto_renew),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Auto Renew"
            className="filter_input"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.auto_renew
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.cost - b.cost,
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Cost"
            className="filter_input"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.cost
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "Renew Status",
      dataIndex: "renew_status",
      key: "renew_status",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.renew_status.localeCompare(b.renew_status),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Renew Status"
            className="filter_input"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.renew_status
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (text) => <span title={text}>{text}</span>,
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.start_date.localeCompare(b.start_date),
      render: (date) => dayjs(date).format("DD-MM-YYYY"),

      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }) => (
      //   <div style={{ padding: 8 }}>
      //     <Input
      //       placeholder="Search by start date"
      //       value={selectedKeys[0]}
      //       onChange={(e) =>
      //         setSelectedKeys(e.target.value ? [e.target.value] : [])
      //       }
      //       onPressEnter={() => {
      //         confirm();
      //       }}
      //       style={{ width: 188, marginBottom: 8, display: "block" }}
      //     />
      //     <Button
      //       type="primary"
      //       onClick={() => {
      //         confirm();
      //       }}
      //       size="small"
      //       style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
      //     >
      //       Search
      //     </Button>
      //     <Button
      //       onClick={() => {
      //         clearFilters();
      //         confirm();
      //       }}
      //       size="small"
      //       style={{ width: 90 }}
      //     >
      //       Reset
      //     </Button>
      //   </div>
      // ),
      // filterIcon: (filtered) => (
      //   <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      // ),
      // onFilter: (value, record) => {
      //   return record.start_date
      //     .toString()
      //     .toLowerCase()
      //     .includes(value.toLowerCase());
      // },
      // render: (text) => <span>{text}</span>,
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      // defaultSortOrder: "ascend",
      sorter: (a, b) => a.end_date.localeCompare(b.end_date),
      render: (date) => dayjs(date).format("DD-MM-YYYY"),

      // filterDropdown: ({
      //   setSelectedKeys,
      //   selectedKeys,
      //   confirm,
      //   clearFilters,
      // }) => (
      //   <div style={{ padding: 8 }}>
      //     <Input
      //       placeholder="Search by end date"
      //       value={selectedKeys[0]}
      //       onChange={(e) =>
      //         setSelectedKeys(e.target.value ? [e.target.value] : [])
      //       }
      //       onPressEnter={() => {
      //         confirm();
      //       }}
      //       style={{ width: 188, marginBottom: 8, display: "block" }}
      //     />
      //     <Button
      //       type="primary"
      //       onClick={() => {
      //         confirm();
      //       }}
      //       size="small"
      //       style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
      //     >
      //       Search
      //     </Button>
      //     <Button
      //       onClick={() => {
      //         clearFilters();
      //         confirm();
      //       }}
      //       size="small"
      //       style={{ width: 90 }}
      //     >
      //       Reset
      //     </Button>
      //   </div>
      // ),
      // filterIcon: (filtered) => (
      //   <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      // ),
      // onFilter: (value, record) => {
      //   return record.end_date
      //     .toString()
      //     .toLowerCase()
      //     .includes(value.toLowerCase());
      // },
      // render: (text) => <span>{text}</span>,
    },
    // {
    //   title: "Action",
    //   key: "operation",
    //   fixed: "right",
    //   width: 100,
    //   render: () => (
    //     <div
    //       style={{
    //         display: "flex",
    //         gap: "15px",
    //       }}
    //     >
    //       <EditOutlined style={{ fontSize: "16px" }} />
    //       <DeleteOutlined style={{ fontSize: "16px" }} />
    //     </div>
    //   ),
    // },
  ];

  const showModal = () => {
    setOpen2(true);
  };

  const handleCancel = () => {
    setOpen2(false);
  };

  const onRowClick = (record) => {
    navigate(`/contracts/${record.contract_id}`);
  };

  const rowProps = (record) => {
    return {
      onClick: () => onRowClick(record),
    };
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const formatDate = (dateString) => {
    // const parts = dateString.split("/");

    // const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

    // return formattedDate;
    return dateString;
  };

  const convertDates = (data) => {
    return data.map((item) => ({
      ...item,
      start_date: formatDate(item.start_date),
      end_date: formatDate(item.end_date),
    }));
  };

  const Start = async () => {
    if (fileList.length == "0") {
      Swal.fire({
        title: "please! upload a file",
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#229849",
        // timer: 2000,
        timerProgressBar: true,
        onClose: () => {
          console.log("Popup closed");
        },
      });
    } else {
      setLoading(true);

      const convertedData = convertDates(csvData);
      await instance
        .post(baseUrl + "/create_multiple_contracts", convertedData)
        .then((response) => {
          console.log(response, "start response");
          if (response) {
            Swal.fire({
              title: response.data.message,
              icon: "success",
              confirmButtonText: "OK",
              confirmButtonColor: "#229849",
              timer: 2000,
              timerProgressBar: true,
              onClose: () => {
                console.log("Popup closed");
              },
            });
            setLoading(false);
            getContracts();
            setOpen2(false);
          }
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data.error,
            icon: "error",
            confirmButtonText: "OK",
            // timer: 2000,
            // timerProgressBar: true,
            onClose: () => {
              console.log("Popup closed");
            },
          });
          console.error("Error:", error);
          setOpen2(false);
          setLoading(false);
        });
    }
  };

  const exportToCSV = () => {
    const csvData = contract.map((item) => ({
      "Contract Name": item.contract_name || "",
      "Contract Number": item.contract_number || "",
      Vendor: item.vendor || "",
      "Customer Email": item.vendor_email || "", // Vendor Email to Customer Email
      Type: item.type || "",
      Approver: item.approver_email || "",
      "Auto Renew": item.auto_renew || "",
      Cost: item.cost || "",
      "Renew Status": item.renew_status || "",
      "Start Date": item.start_date || "",
      "End Date": item.end_date || "",
    }));

    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(csvData[0]).join(",") +
      "\n" +
      csvData.map((row) => Object.values(row).join(",")).join("\n");

    // Create a CSV file and initiate download
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "contracts.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handleUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const rows = text.split("\n");

      // Extract keys from the first row
      const keys = rows[0].split(",");

      const data = [];
      for (let i = 1; i < rows.length; i++) {
        const row = rows[i].trim();
        if (row) {
          const values = row.split(",");
          const obj = {};
          keys.forEach((key, index) => {
            obj[key.trim()] = values[index] ? values[index].trim() : "";
          });
          data.push(obj);
        }
      }

      setCsvData(data);
    };
    reader.readAsText(file);
  };

  const props = {
    name: "file",
    multiple: false, // Allow only one file to be uploaded at a time
    // action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      const allowedExtensions = [".csv", ".xls", ".xlsx"];
      const extension = info.file.name.split(".").pop();
      const isAllowed = allowedExtensions.includes(
        "." + extension.toLowerCase()
      );
      if (!isAllowed) {
      } else {
        let newFileList = [...info.fileList];
        newFileList = newFileList.slice(-1);

        // 2. Read from response and show file link
        newFileList = newFileList.map((file) => {
          if (file.response) {
            // Component will show file.url as link
            file.url = file.response.url;
          }
          return file;
        });
        setFileList(newFileList);
      }
    },

    beforeUpload: (file) => {
      const allowedExtensions = [".csv", ".xls", ".xlsx"];
      const extension = file.name.split(".").pop();
      const isAllowed = allowedExtensions.includes(
        "." + extension.toLowerCase()
      );
      if (!isAllowed) {
        message.error(`File type not supported: ${extension}`);
      } else {
        handleUpload(file);
      }
      return isAllowed;
    },
  };

  const handleDeleteRows = async () => {
    await instance
      .delete(baseUrl + `/deletecontracts?ids=${selectedRowKeys}`)
      .then((res) => {
        message.success(res.data.message);
        getContracts();
        setSelectedRowKeys([]);
      })
      .catch((err) => {
        message.error(err.response.data.error);
        console.log("err", err);
      });
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={open2}
        title="Import File"
        onCancel={handleCancel}
        footer={[
          <Button key="submit" type="primary" loading={loading} onClick={Start}>
            Submit
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Dragger {...props} fileList={fileList}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ color: "#229849" }} />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">File Type: csv or excel</p>
          </Dragger>
        </Spin>
      </Modal>

      <div
        style={{
          width: "92vw",
          padding: "0px 2px 0px 23px ",
        }}
      >
        {localStorage.getItem("role") !== "User" && (
          <div className="btn-wrapper">
            {selectedRowKeys.length > 0 && (
              <button className="delete-btn" onClick={handleDeleteRows}>
                Delete
              </button>
            )}

            <div
              style={{
                display: "flex",
                flex: 1,
                alignItems: "center",
                gap: "10px",
                justifyContent: "flex-end",
                paddingBottom: "20px",
              }}
            >
              <Button
                className="import_btn"
                icon={<ExportOutlined />}
                onClick={exportToCSV}
              >
                Export
              </Button>

              <Button
                className="import_btn"
                icon={<DownloadOutlined />}
                onClick={showModal}
              >
                Import
              </Button>

              <Link to="/addcontract" style={{ textDecoration: "none" }}>
                <Button className="create_btn" value="large">
                  Add New
                </Button>
              </Link>
            </div>
          </div>
        )}
        <div
          style={{
            width: "92vw",
            flexWrap: "wrap",
          }}
        >
          <Spin
            spinning={loading == true ? loading : null}
            indicator={<LoadingIndicator />}
          >
            <Table
              className="contract-list"
              columns={columns}
              dataSource={contract}
              onRow={rowProps}
              rowSelection={{
                ...rowSelection,
              }}
              rowKey="contract_id"
              showSizeChanger="true"
              pagination={{
                defaultPageSize: 10,
                pageSizeOptions: [5, 50, contract.length],
              }}
              scroll={{
                x: 2200,
              }}
            />
          </Spin>
        </div>
      </div>
    </>
  );
}

export default Index;
