import React, { useEffect, useState } from "react";
import { Table, Modal, Form, Input, Button, Select, message, Spin } from "antd";
import "./users.css";
import { baseUrl } from "../../utils/axios";
import instance from "../../utils/axios";
import edit from "../../Assests/images/edit.png";
import del from "../../Assests/images/delete.png";
import { Link } from "react-router-dom";
import { SearchOutlined, LoadingOutlined } from "@ant-design/icons";

function Index() {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editUserData, setEditUserData] = useState({
    user_id: "",
    role: "",
    password: "",
    name: "",
  });

  const getUsers = async () => {
    setLoading(true);
    try {
      const res = await instance.get(baseUrl + "/getallusers");
      if (res) {
        setUsers(res.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      setIsLoading(false);
    }
  };
  const LoadingIndicator = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <LoadingOutlined
        style={{
          fontSize: 24,
          color: "#229849",
        }}
        spin
      />
    </div>
  );
  const deleteUser = async (user_id) => {
    try {
      const response = await instance.post(baseUrl + `/DeleteUser`, {
        user_id: user_id,
      });

      getUsers();
      message.success(response.data);
    } catch (error) {
      console.error("Error deleting user:", error.response.data);
      message.error(error.response.data);
    }
  };

  const editUser = async (user_id, values) => {
    setIsLoading(true);
    try {
      const response = await instance.post(
        `${baseUrl}/Edituser`,

        {
          user_id: user_id,
          name: values.name,
          role: values.role,
          password: values.password,
        }
      );

      setSelectedTicket(null);
      getUsers();
    } catch (error) {
      console.error("Error editing user:", error);
    }
    setIsLoading(false);
  };

  const handleEditClick = (record) => {
    setSelectedTicket(record);
    setEditUserData({
      user_id: record.user_id,
      role: record.role,
      password: record.password,
      name: record.name,
    });
  };

  const onFinishEdit = async (values) => {
    try {
      await editUser(selectedTicket.user_id, values);
      form.resetFields();
      setSelectedTicket(null);
    } catch (error) {
      console.error("Error editing user:", error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const columns = [
    {
      title: <span className="table-title">User Name</span>,
      width: 100,
      dataIndex: "name",
      key: "name",

      sorter: (a, b) => a.name.localeCompare(b.name),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            className="filter_input"
            placeholder="Search Name"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.name
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      // render: (name, record) => <a className="contact-column">{name}</a>,
    },
    {
      title: <span className="table-title">Role</span>,
      width: 100,
      dataIndex: "role",
      key: "role",

      sorter: (a, b) => a.role.localeCompare(b.role),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            className="filter_input"
            placeholder="Search Role"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.role
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      // render: (name, record) => <a className="contact-column">{name}</a>,
    },
    {
      title: <span className="table-title">Email</span>,
      width: 100,
      dataIndex: "email",
      key: "email",

      sorter: (a, b) => a.email.localeCompare(b.email),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            className="filter_input"
            placeholder="Search Email"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.email
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      // render: (name, record) => <a className="contact-column">{name}</a>,
    },
    {
      title: <span className="table-title">Status</span>,
      width: 70,
      dataIndex: "status",
      key: "status",

      sorter: (a, b) => a.status.localeCompare(b.status),
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            className="filter_input"
            placeholder="Search Status"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => {
              confirm();
            }}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Button
            type="primary"
            onClick={() => {
              confirm();
            }}
            size="small"
            style={{ width: 90, marginRight: 8, backgroundColor: "#76BA1B" }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters();
            }}
            size="small"
            style={{ width: 90 }}
            className="reset_btn"
          >
            Reset
          </Button>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "green" : undefined }} />
      ),
      onFilter: (value, record) => {
        return record.status
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      render: (name, record) => <a className="contact-column">{name}</a>,

      render: (status) => (
        <div
          style={{
            width: "60%",
            margin: "0 auto",
            backgroundColor: "#229849",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
            borderRadius: "20px",
          }}
        >
          <p
            value={status}
            style={{
              margin: 0,
              width: "100%",
              textAlign: "center",
              padding: "4px",
            }}
          >
            {status}
          </p>
        </div>
      ),
    },
    {
      title: <span className="table-title">Creation Date</span>,
      width: 180,
      dataIndex: "creation_date",
      key: "creation_date",
    },
    {
      title: (
        <span
          className="table-action"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Action
        </span>
      ),
      width: 100,
      key: "action",
      fixed: "right",
      render: (_, record, rowIndex) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            cursor: "pointer",
          }}
        >
          <div>
            {" "}
            <img
              src={edit}
              width={16}
              height={16}
              onClick={() => {
                handleEditClick(record);
              }}
            />
          </div>
          <div>
            {" "}
            <img
              src={del}
              width={14}
              height={18}
              onClick={() => {
                Modal.confirm({
                  title: "Confirm Delete",
                  content: "Are you sure you want to delete this user?",
                  okText: "Delete",
                  cancelText: "Cancel",
                  onOk: () => {
                    deleteUser(record.user_id);
                  },
                });
              }}
            />
          </div>
        </div>
      ),
    },
  ];

  const selectRole = [
    { value: "Admin", label: "Admin" },
    { value: "User", label: "User" },
  ];

  return (
    <>
      <div
        className=""
        style={{
          paddingBottom: "10px",
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "92vw",
        }}
      >
        <Link to="/adduser">
          <Button
            value="large"
            style={{
              backgroundColor: "#229849",
              color: "white",
            }}
          >
            Add User
          </Button>
        </Link>
        {/* <Button value="large" className="filter-btn" onClick={showDrawer}>
            Filter
          </Button> */}
      </div>
      <Modal
        title="Edit User"
        visible={selectedTicket !== null}
        onCancel={() => setSelectedTicket(null)}
        footer={null}
      >
        <Form form={form} onFinish={onFinishEdit} initialValues={editUserData}>
          <div>
            <div className="edit-input" style={{ paddingBottom: "15px" }}>
              <label className="label" htmlFor="name">
                User Name
              </label>
              <Input
                value={editUserData.name}
                name="name"
                onChange={(e) =>
                  setEditUserData({ ...editUserData, name: e.target.value })
                }
              />
            </div>

            <div className="input">
              <label className="label" htmlFor="type">
                Role
              </label>
              <Select
                value={editUserData.role}
                name="role"
                onChange={(value) =>
                  setEditUserData({ ...editUserData, role: value })
                }
                style={{
                  width: "100%",
                }}
                options={selectRole.map((role) => ({
                  value: role.value,
                  label: role.label,
                }))}
              />
            </div>

            <div className="edit-input" style={{ paddingBottom: "15px" }}>
              <label className="label" htmlFor="password">
                Password
              </label>
              <Input
                value={editUserData.password}
                name="password"
                onChange={(e) =>
                  setEditUserData({ ...editUserData, password: e.target.value })
                }
              />
            </div>
          </div>
        </Form>
        <div style={{ textAlign: "right" }}>
          <Button
            style={{
              position: "relative",
              backgroundColor: "#229849",
              color: "white",
            }}
            type="primary"
            htmlType="submit"
            onClick={() => editUser(editUserData.user_id, editUserData)}
          >
            Update
          </Button>
        </div>
      </Modal>

      <div>
        <div style={{ width: "92vw", padding: "0px 0px 0px 23px" }}>
          <Spin
            spinning={loading == true ? loading : null}
            indicator={<LoadingIndicator />}
          >
            <Table
              columns={columns}
              dataSource={users}
              scroll={{
                x: 1200,
              }}
            />
          </Spin>
        </div>
      </div>
    </>
  );
}

export default Index;
