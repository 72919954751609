import styled from "styled-components";

export const StyledOrgcard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  height: 110px;
  background: #ffffff;
  box-shadow: 3px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  border-top: 4px solid #2d482f;
  gap: 15px;
`;

export const StyledFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
`;

export const StyledMainDiv = styled.div`
  /* max-width: 100%; */
  /* height:100vh; */
  /* background-color:green; */
`;

export const StyledSideBar = styled.div` 
height:99.5vh;
background: white;

/* width: ${({ isOpen }) => (isOpen ? "90%" : "52%")};
  transition: all 0.5s; */
position: fixed;
// width: 5%;
`;

export const StyleOrgSideBar = styled.div`
  height: 100%;
  background: #2d482f;
`;

export const Header = styled.div`
  // height: 7.4%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 15px;
  border-width: 0px 0px 2px 0px;
  border-style: solid;
  // border-color: #e3e3e3;
  border: 1px solid #e3e3e3;
`;

export const ToggleIcon = styled.div`
  cursor: pointer;
  color: #fff;
`;

export const StyledHeading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const StyledNonActiveLink = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  min-height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    180deg,
    #849b86 -31.63%,
    rgba(78, 115, 82, 0) 112.24%
  );
  border-top: 1px solid #ffffff;
  cursor: pointer;
`;

export const StyledActiveLink = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #a5cfa9;
  cursor: pointer;
`;

export const StyledNonActiveText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #fafffa;
`;

export const StyledActiveText = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000;
`;

export const StyledLinks = styled.div`
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  /* overflow-y: scroll; */
  /* border:1px solid red; */
`;

export const StyledMain = styled.div`
  width: 100%;
  /* height: 100vh; */
  /* padding-left: 25px; */
  padding-right: 25px;
  overflow-y: scroll;
`;

export const TopBarMain = styled.nav`
  min-width: 94%;
  height: 45px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: row;
  padding-top: 5px !important;
  position: relative;
`;

export const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding-left: 25px;
  /* border: 2px solid red; */
  /* position: relative; */
  overflow: hidden;
  border: 1px solid blue;
`;

export const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  gap: 25px;
  padding-right: 4px;
  border: 1px solid green;

  /* width: 100%; */
`;

export const OrganizationHeading = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  margin-left: 10px;
`;
