import "./App.css";
import React from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SideNavbar from "./Components/landing/components/SidebarR.jsx";
import "../node_modules/antd/dist/antd";
import "antd/dist/reset.css";
import { useEffect, useState } from "react";
import Login from "./Pages/Login";
import Home from "./Pages/Home/Index";
import Contracts from "./Pages/Contracts/Index";
import Addcontract from "./Pages/Addcontract/Index";
import Tickets from "./Pages/Tickets/Index";
import Newticket from "./Pages/Newticket/Index";
import Addvendor from "./Pages/Addvendor/Index";
import Adduser from "./Pages/Adduser/index.jsx";
import ContractDetail from "./Pages/ContractDetail/ContractDetail";
import TicketDetail from "./Pages/TicketDetail/TicketDetail";
import Users from "./Pages/Users/index.jsx";
import Customers from "./Pages/Customers/Customers.jsx";
import "react-phone-input-2/lib/style.css";
import Reports from "./Pages/reports/Reports.jsx";

function App() {
  const { pathname } = useLocation();
  let navigate = useNavigate();
  const [token, setToken] = useState("");

  useEffect(() => {
    let mainKey = localStorage.getItem("auth_key");
    if (mainKey) {
      setToken(mainKey);
      if (pathname === "/login") {
        navigate("/");
      }
    } else {
      navigate("/login");
    }
  }, [pathname]);

  return (
    <>
      {token ? (
        <>
          {pathname !== "/login" && localStorage.getItem("role") === "Admin" ? (
            <>
              <SideNavbar>
                <Routes>
                  <Route path="/" exact element={<Home />} />
                  <Route path="/contracts" exact element={<Contracts />} />
                  <Route
                    path="/contracts/:id"
                    exact
                    element={<ContractDetail />}
                  />
                  <Route path="/tickets" exact element={<Tickets />} />
                  <Route path="/tickets/:id" exact element={<TicketDetail />} />
                  <Route path="/addcontract" exact element={<Addcontract />} />
                  <Route path="/newticket" exact element={<Newticket />} />
                  <Route path="/addvendor" exact element={<Addvendor />} />
                  <Route path="/adduser" exact element={<Adduser />} />
                  <Route path="/users" exact element={<Users />} />
                  <Route path="/customers" exact element={<Customers />} />
                  {/* <Route path="/reports" exact element={<Reports />} /> */}
                </Routes>
              </SideNavbar>
            </>
          ) : pathname !== "/login" &&
            localStorage.getItem("role") === "User" ? (
            <>
              <SideNavbar>
                <Routes>
                  {/* <Route path="/" exact element={<Home />} /> */}
                  <Route path="/tickets" exact element={<Tickets />} />
                  <Route path="/tickets/:id" exact element={<TicketDetail />} />
                  <Route path="/newticket" exact element={<Newticket />} />
                  <Route path="/contracts" exact element={<Contracts />} />
                  <Route
                    path="/contracts/:id"
                    exact
                    element={<ContractDetail />}
                  />
                  <Route path="/customers" exact element={<Customers />} />
                </Routes>
              </SideNavbar>
            </>
          ) : pathname !== "/login" &&
            localStorage.getItem("role") === "Manager" ? (
            <>
              <SideNavbar>
                <Routes>
                  <Route path="/" exact element={<Home />} />
                </Routes>
              </SideNavbar>
            </>
          ) : pathname !== "/login" &&
            localStorage.getItem("role") === "Super_Admin" ? (
            <>
              <SideNavbar>
                <Routes>
                  <Route path="/" exact element={<Home />} />
                  <Route path="/tickets" exact element={<Tickets />} />
                  <Route path="/contracts" exact element={<Contracts />} />
                  <Route path="/addcontract" exact element={<Addcontract />} />
                  <Route path="/newticket" exact element={<Newticket />} />
                  <Route path="/customers" exact element={<Customers />} />
                </Routes>
              </SideNavbar>
            </>
          ) : (
            <>
              <Routes>
                <Route path="/login" exact element={<Login />} />
              </Routes>
            </>
          )}
        </>
      ) : (
        <>
          <Routes>
            <Route path="/login" exact element={<Login />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;

// import React, { useEffect, useState } from "react";
// import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
// import SideNavbar from "./Components/landing/components/SidebarR.jsx";
// import Login from "./Pages/Login";
// import Home from "./Pages/Home/Index";
// import Header from "./Components/Header/Index";
// import Contracts from "./Pages/Contracts/Index";
// import Addcontract from "./Pages/Addcontract/Index";
// import Tickets from "./Pages/Tickets/Index";
// import Newticket from "./Pages/Newticket/Index";
// import Addvendor from "./Pages/Addvendor/Index";
// import ContractDetail from "./Pages/ContractDetail/ContractDetail";
// import TicketDetail from "./Pages/TicketDetail/TicketDetail";

// function App() {
//   const { pathname } = useLocation();
//   let navigate = useNavigate();
//   const [token, setToken] = useState("");

//   useEffect(() => {
//     let mainKey = localStorage.getItem("auth_key");
//     if (mainKey) {
//       setToken(mainKey);
//       if (pathname === "/login") {
//         navigate("/");
//       }
//     } else {
//       navigate("/login");
//     }
//   }, [pathname]);

//   return (
//     <>
//       {token ? (
//         <>
//           {pathname !== "/login" &&
//           (localStorage.getItem("role") === "Admin" ||
//             localStorage.getItem("role") === "Super_Admin") ? (
//             <>
//               <SideNavbar>
//                 <Routes>
//                   {/* Admin and Super_Admin Routes */}
//                   <Route path="/" exact element={<Home />} />
//                   <Route path="/contracts" exact element={<Contracts />} />
//                   <Route
//                     path="/contracts/:id"
//                     exact
//                     element={<ContractDetail />}
//                   />
//                   <Route path="/tickets" exact element={<Tickets />} />
//                   <Route
//                     path="/tickets/:id"
//                     exact
//                     element={<TicketDetail />}
//                   />
//                   <Route path="/addcontract" exact element={<Addcontract />} />
//                   <Route path="/newticket" exact element={<Newticket />} />
//                   <Route path="/addvendor" exact element={<Addvendor />} />
//                 </Routes>
//               </SideNavbar>
//             </>
//           ) : pathname !== "/login" &&
//             localStorage.getItem("role") === "User" ? (
//             <>
//               <SideNavbar>
//                 <Routes>
//                   {/* User Routes */}
//                   <Route path="/" exact element={<Home />} />
//                   <Route path="/contracts" exact element={<Contracts />} />
//                 </Routes>
//               </SideNavbar>
//             </>
//           ) : pathname !== "/login" &&
//             localStorage.getItem("role") === "Manager" ? (
//             <>
//               <SideNavbar>
//                 <Routes>
//                   {/* Manager Routes */}
//                   <Route path="/" exact element={<Home />} />
//                 </Routes>
//               </SideNavbar>
//             </>
//           ) : pathname !== "/login" &&
//             localStorage.getItem("role") === "Help_Desk" ? (
//             <>
//               <SideNavbar>
//                 <Routes>
//                   {/* Help Desk Routes */}
//                   {/* <Route path="/" exact element={<Home />} /> */}
//                   <Route path="/tickets" exact element={<Tickets />} />
//                   {/* Add additional routes specific to the Help Desk role */}
//                   {/* Example: <Route path="/helpdesk" exact element={<HelpDeskPage />} /> */}
//                 </Routes>
//               </SideNavbar>
//             </>
//           ) : (
//             <>
//               <Routes>
//                 <Route path="/login" exact element={<Login />} />
//               </Routes>
//             </>
//           )}
//         </>
//       ) : (
//         <>
//           <Routes>
//             <Route path="/login" exact element={<Login />} />
//           </Routes>
//         </>
//       )}
//     </>
//   );
// }

// export default App;
