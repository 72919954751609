import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { Image, message, Spin, Select } from "antd";
import { baseUrl } from "../../utils/axios";
import instance from "../../utils/axios";
import TicketHistoryDetail from "../../Components/TicketHistoryDetail/TicketHistoryDetail";
import profileimg from "./../../Assests/images/profileimg.png";
import commentimg from "./../../Assests/images/ppic.png";
import backarrow from "./../../Assests/images/backarrow1.png";
import { Link } from "react-router-dom";
import "./ticketDetail.css";
import { Option } from "antd/es/mentions";
import AppContext from "./../../AppContext";

function TicketDetail() {
  const { id } = useParams();
  const [detail, setDetail] = useState(null);
  const [history, setHistory] = useState([]);
  const [comments, setComments] = useState([]);
  const [editedComment, setEditedComment] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { status } = useContext(AppContext);
  const [editStatus, setEditStatus] = useState("");

  const getTicketHistory = async (id) => {
    try {
      const response = await instance.get(`${baseUrl}/TicketHistory/${id}`);
      setHistory(response.data);
      setDetail(response.data[0]);
      setEditStatus(response.data[0].status);

      console.log("|response.data[0].status", response.data[0].status);
    } catch (error) {
      console.error("Error fetching ticket history for ID", id, ":", error);
    }
  };

  const getComments = async (id) => {
    try {
      const response = await instance.get(
        `${baseUrl}/getTicketByComment/${id}`
      );
      console.log("Comments for ID :", response.data);
      setComments(response.data);
    } catch (error) {
      console.error("Error fetching comments for ID", id, ":", error);
    }
  };

  const handleCommentSubmit = async () => {
    try {
      if (editedComment.trim() === "") {
        // Display an error message or handle the empty comment case as needed
        console.error("Comment cannot be empty");
        return;
      }

      if (editingCommentId !== null) {
        const response = await instance.post(`${baseUrl}/EditComment`, {
          comment_id: editingCommentId,
          comments: editedComment,
        });
        // console.log("Comment Edit response:", response.data);

        setComments((prevComments) =>
          prevComments.map((comment) =>
            comment.comment_id === editingCommentId
              ? { ...comment, comments: editedComment }
              : comment
          )
        );
      } else {
        const response = await instance.post(`${baseUrl}/addComment`, {
          comments: editedComment,
          ticket_id: id,
        });
        // console.log("Comment posted successfully:", response.data);
        await getComments(id);
      }

      setEditedComment("");
      setEditingCommentId(null);
    } catch (error) {
      console.error("Error posting/editing comment:", error.message);
    }
  };

  const handleCommentEdit = (commentId) => {
    const selectedComment = comments.find(
      (comment) => comment.comment_id === commentId
    );

    if (selectedComment) {
      setEditedComment(selectedComment.comments);
      setEditingCommentId(commentId);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      const response = await instance.post(`${baseUrl}/DeleteCommnet`, {
        comment_id: commentId,
      });

      if (response.status === 200) {
        // Refetch comments after deletion.
        getComments(id);
        // console.log("Comment deleted successfully");
      } else {
      }
    } catch (error) {
      console.error("Error deleting comment:", error.message);
    }
  };

  useEffect(() => {
    if (id) {
      getTicketHistory(id);
      getComments(id);
    }
  }, [id]);

  const storedUserRole = localStorage.getItem("name");

  const handleSelectEdit = async (key, value) => {
    setEditStatus(value);
    await instance.put(`${baseUrl}/editTicket/${id}`, {
      status: value,
    });
  };

  return (
    <>
      {/* <div className="input"> */}
      {isLoading && (
        <Spin
          tip="Submitting..."
          size="large"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "white",
          }}
        />
      )}
      {/* </div> */}
      <div style={{ padding: "20px", width: "90vw" }}>
        <div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                width: "90vw",
                padding: "0px 0px 20px 0px",
                margin: "0px",
                maxHeight: "50px",
              }}
            >
              <Link to="/tickets">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src={backarrow} height={15} width={15} />
                </div>
              </Link>
              <h2 style={{ margin: 0 }}>{detail && detail.contact_name}</h2>
            </div>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "2px solid #E7E7E7",
                padding: "0px 20px 0px 0px",
                margin: "0px",
                minHeight: "150px",
              }}
            >
              {/* <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            > */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <Image
                  src={profileimg}
                  width={120}
                  height={120}
                  preview={false}
                />
                <div
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  <p className="label">Name :</p>
                  <p className="label">Email :</p>
                  <p className="label">Language :</p>
                </div>
                <div>
                  <p className="label-value">
                    {" "}
                    &nbsp;&nbsp;&nbsp;{detail && detail.contact_name}
                  </p>
                  <p className="label-value">
                    {" "}
                    &nbsp;&nbsp;&nbsp;{detail && detail.contact_email}
                  </p>
                  <p className="label-value"> &nbsp;&nbsp;&nbsp;English</p>
                </div>
              </div>
              <Select
                className="ticket_details_selector"
                value={editStatus}
                name="status"
                onChange={(value) => handleSelectEdit("status", value)}
                style={{
                  width: "150px",
                  height: "40px",
                  backgroundColor:
                    editStatus == "Resolved"
                      ? "#D5E4D6"
                      : editStatus == "Closed"
                      ? "#E8F2E6"
                      : editStatus == "Open"
                      ? "#E6F3D4"
                      : editStatus == "Pending"
                      ? "#FFEEEE"
                      : editStatus == "RMA"
                      ? "#FFF6E5"
                      : editStatus == "Waiting on customer"
                      ? "#E2DDD4"
                      : editStatus == "Waiting on vendor"
                      ? "#F0ECD8"
                      : editStatus == "Scheduled"
                      ? "#EFE4DB"
                      : editStatus == "Recorded"
                      ? "#EAE4E4"
                      : "#ff9999",
                  color:
                    editStatus == "Resolved"
                      ? "#2D482F"
                      : editStatus == "Closed"
                      ? "#68BB59"
                      : editStatus == "Open"
                      ? "#68BB59"
                      : editStatus == "Pending"
                      ? "#DA0404"
                      : editStatus == "RMA"
                      ? "#EB5757"
                      : editStatus == "Waiting on customer"
                      ? "#FFA500"
                      : editStatus == "Waiting on vendor"
                      ? "#C9BD83"
                      : editStatus == "Scheduled"
                      ? "#FF7C02"
                      : editStatus == "Recorded"
                      ? "#6B6B6B"
                      : "#FFFFFF",
                }}
              >
                {status.map((option) => (
                  <Option
                    style={{
                      marginBottom: "5px",
                      backgroundColor:
                        option.value == "Resolved"
                          ? "#D5E4D6"
                          : option.value == "Closed"
                          ? "#E8F2E6"
                          : option.value == "Open"
                          ? "#E6F3D4"
                          : option.value == "Pending"
                          ? "#FFEEEE"
                          : option.value == "RMA"
                          ? "#FFF6E5"
                          : option.value == "Waiting on customer"
                          ? "#E2DDD4"
                          : option.value == "Waiting on vendor"
                          ? "#F0ECD8"
                          : option.value == "Scheduled"
                          ? "#EFE4DB"
                          : option.value == "Recorded"
                          ? "#EAE4E4"
                          : "null",
                      color:
                        option.value == "Resolved"
                          ? "#2D482F"
                          : option.value == "Closed"
                          ? "#68BB59"
                          : option.value == "Open"
                          ? "#68BB59"
                          : option.value == "Pending"
                          ? "#DA0404"
                          : option.value == "RMA"
                          ? "#EB5757"
                          : option.value == "Waiting on customer"
                          ? "#FFA500"
                          : option.value == "Waiting on vendor"
                          ? "#C9BD83"
                          : option.value == "Scheduled"
                          ? "#FF7C02"
                          : option.value == "Recorded"
                          ? "#6B6B6B"
                          : "null",
                    }}
                    key={option.value}
                    value={option.value}
                  >
                    {/* {option.label} */}
                  </Option>
                ))}
              </Select>
              {/* </div> */}
            </div>
          </div>

          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "2px solid #E7E7E7",
                padding: "0px",
                margin: "0px",
                height: "40px",
                flexBasis: "50%",
              }}
            >
              <div style={{ gap: "50px", display: "flex" }}>
                <h3
                  style={{
                    padding: "0px 0px 0px 5px",
                    color: "#229849",
                  }}
                >
                  Tickets
                </h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                border: "2px solid #E7E7E7",
                padding: "0px",
                margin: "0px",
                height: "40px",
                flexBasis: "50%",
              }}
            >
              <div>
                <h3
                  style={{
                    padding: "0px 0px 0px 5px",
                    color: "#229849",
                  }}
                >
                  Comments
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div style={{ display: "flex" }}>
          <div
            style={{
              paddingTop: "20px",
              border: "1px solid #E7E7E7",
              flexBasis: "50%",
            }}
          >
            {history !== "No ticket record found" &&
              history.map((item, index) => (
                <div style={{ paddingTop: "0px" }} key={index}>
                  <TicketHistoryDetail
                    style={{ padding: "10px" }}
                    ticketdate={item.ticket_modification_date}
                    subject={item.ticket_subject}
                    status={item.status}
                    agent={item.agent_name}
                  />
                </div>
              ))}
          </div>

          <div style={{ border: "1px solid #E7E7E7", flexBasis: "50%" }}>
            <div
              style={{
                padding: "0px 0px 20px 0px",
                flexBasis: "50%",
                maxHeight: "400px",
                overflowY: "auto",
              }}
            >
              {comments &&
                comments.map((comment) => (
                  <div key={comment.comment_id} style={{ paddingTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 30px",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={commentimg}
                        alt="Comment"
                        width={40}
                        height={40}
                      />
                      <div>
                        <h4 style={{ color: "#229849" }}>{comment.user_id}</h4>
                        <label style={{ color: "#CDC6C6", fontSize: "12px" }}>
                          {comment.creation_date}
                        </label>
                      </div>
                    </div>
                    <p
                      className="para-graph"
                      style={{ wordWrap: "break-word", maxWidth: "60ch" }}
                    >
                      {comment.comments}
                    </p>
                    {comment.user_id === storedUserRole && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          padding: "5px 0px 0px 80px",
                          gap: "20px",
                        }}
                      >
                        <label
                          style={{ color: "#CDC6C6", fontSize: "12px" }}
                          onClick={() => handleCommentEdit(comment.comment_id)}
                        >
                          {editingCommentId === comment.comment_id
                            ? "Update"
                            : "Edit"}
                        </label>
                        <label
                          style={{ color: "#CDC6C6", fontSize: "12px" }}
                          onClick={() =>
                            handleDeleteComment(comment.comment_id)
                          }
                        >
                          {" "}
                          Delete
                        </label>
                      </div>
                    )}
                  </div>
                ))}
            </div>

            <div style={{ width: "100%", padding: "0px 30px 30px 30px" }}>
              <input
                style={{
                  width: "90%",
                  padding: "8px",
                  borderRadius: "4px 0px 0px 4px",
                  border: "1px solid #D3D3D3",
                }}
                placeholder="Write Comment"
                value={editedComment}
                onChange={(e) => setEditedComment(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleCommentSubmit();
                  }
                }}
              />
              <button
                style={{
                  width: "10%",
                  padding: "8px 15px 8px 5px",
                  backgroundColor: "#229849",
                  color: "#FFFFFF",
                  borderRadius: "0px 4px 4px 0px",
                  border: "1px solid #229849",
                }}
                onClick={handleCommentSubmit}
                disabled={!editedComment.trim()}
              >
                {editingCommentId !== null ? "Update" : "Post"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TicketDetail;
