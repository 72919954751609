// import React, { useState } from "react";
// import { Modal, Row, Col, DatePicker } from "antd";
// import {
//   StyledSelect,
//   InputWrapper,
//   StyledInput,
// } from "../../../Pages/Home/main.styles.js";
// import moment from "moment";
// import axios, { baseUrl, header } from "../../../utils/axios";
// import { useEffect } from "react";
// import Swal from "sweetalert2";
// export default function CreatTicket({
//   iscreateTicketModalVisible,
//   showModal,
//   cancelModal,
// }) {
//   const [assignTo, setAssignTo] = useState("");
//   const [priority, setPriority] = useState("");
//   const [description, setDescription] = useState("");
//   const [dueDate, setDueDate] = useState("");
//   const [ticketType, setTicketType] = useState("");

//   // const [title, setTitle] = useState("");
//   // const [description, setDescription] = useState("");
//   // const [assignedManager, setAssignedManager] = useState("");

//   const openSweetAlert = (title, type) => {
//     Swal.fire({
//       title,
//       type,
//     });
//   };
//   const onChangeDueDate = (date, dateString) => {
//     console.log(date, dateString);
//     setDueDate(dateString);
//   };

//   const handleFormSubmit = async (event) => {
//     event.preventDefault();

//     const ticketData = {
//       assignTo,
//       priority,
//       dueDate,
//       description,
//       ticketType,
//     };

//     try {
//       const response = await axios.post("/api/tickets", ticketData);
//       console.log(response.data);

//       // Clear form fields after successful submission
//       setAssignTo("");
//       setPriority("");
//       setDueDate("");
//       setDescription("");
//       setTicketType("");
//     } catch (error) {
//       console.error(error);
//     }
//   };

//   return (
//     <>
//       {iscreateTicketModalVisible ? (
//         <Modal
//           title=""
//           open={showModal}
//           onCancel={cancelModal}
//           footer={false}
//           closable={true}
//           maskClosable={false}
//           centered
//           width={650}
//           bodyStyle={{ padding: "40px" }}
//         >
//           <>
//             <div
//               style={{
//                 fontWeight: "700",
//                 fontSize: "18px",
//                 color: "#2D482F",
//                 textAlign: "center",
//                 marginBottom: "2%",
//               }}
//             >
//               Create Ticket
//             </div>
//             {/* <form onSubmit={handleFormSubmit}>
//               <label>
//                 Title:
//                 <input
//                   type="text"
//                   value={title}
//                   onChange={(e) => setTitle(e.target.value)}
//                 />
//               </label>
//               <br />
//               <label>
//                 Description:
//                 <textarea
//                   value={description}
//                   onChange={(e) => setDescription(e.target.value)}
//                 />
//               </label>
//               <br />
//               <label>
//                 Assigned Manager:
//                 <input
//                   type="text"
//                   value={assignedManager}
//                   onChange={(e) => setAssignedManager(e.target.value)}
//                 />
//               </label>
//               <br />
//               <button type="submit">Create Ticket</button>
//             </form> */}
//             <form onSubmit={handleFormSubmit}>
//               <Row gutter={[16, 24]} style={{ marginTop: "2%" }}>
//                 <Col span={12}>
//                   <InputWrapper>
//                     Project&nbsp;<span style={{ color: "red" }}>*</span>
//                     <div style={{ marginTop: "10px" }}>
//                       <StyledSelect
//                         required
//                         onChange={(e) => {
//                           setAssignTo(e.target.value);
//                         }}
//                       >
//                         <option value={assignTo} disabled selected hidden>
//                           Select User
//                         </option>
//                         <option value="User 1">User 1</option>
//                         <option value="User 2">User 2</option>
//                       </StyledSelect>
//                     </div>
//                   </InputWrapper>
//                 </Col>
//                 <Col span={12}>
//                   <InputWrapper>
//                     Due Date&nbsp;<span style={{ color: "red" }}>*</span>
//                     <DatePicker
//                       onChange={onChangeDueDate}
//                       defaultValue={
//                         dueDate ? moment(dueDate, "DD-MM-YYYY") : null
//                       }
//                       disabledDate={(current) => {
//                         let customDate = moment().format("DD-MM-YYYY");
//                         return (
//                           current && current < moment(customDate, "DD-MM-YYYY")
//                         );
//                       }}
//                       style={{
//                         width: "100%",
//                         borderRadius: "8px",
//                         marginTop: "10px",
//                       }}
//                       format="DD-MM-YYYY"
//                     />
//                   </InputWrapper>
//                 </Col>
//               </Row>
//               <Row gutter={[16, 24]} style={{ marginTop: "2%" }}>
//                 <Col span={12}>
//                   <InputWrapper>
//                     Ticket Type &nbsp;<span style={{ color: "red" }}>*</span>
//                     <div style={{ marginTop: "10px" }}>
//                       <StyledSelect
//                         required
//                         onChange={(e) => {
//                           setTicketType(e.target.value);
//                         }}
//                       >
//                         <option value={ticketType} disabled selected hidden>
//                           Choose Ticket Type
//                         </option>
//                         <option value="Development">Development</option>
//                         <option value="Testing">Testing</option>
//                       </StyledSelect>
//                     </div>
//                   </InputWrapper>
//                 </Col>
//                 <Col span={12}>
//                   <InputWrapper>
//                     Priority &nbsp;<span style={{ color: "red" }}>*</span>
//                     <div style={{ marginTop: "10px" }}>
//                       <StyledSelect
//                         required
//                         onChange={(e) => {
//                           setPriority(e.target.value);
//                         }}
//                       >
//                         <option value={priority} disabled selected hidden>
//                           Select Ticket Priority
//                         </option>
//                         <option value="Low">Low</option>
//                         <option value="Medium">Medium</option>
//                         <option value="High">High</option>
//                       </StyledSelect>
//                     </div>
//                   </InputWrapper>
//                 </Col>
//               </Row>
//               <Row gutter={[16, 24]} style={{ marginTop: "2%" }}>
//                 <Col span={24}>
//                   <InputWrapper>
//                     Question
//                     <div style={{ marginTop: "10px" }}>
//                       <StyledInput
//                         value={description}
//                         onChange={(e) => setDescription(e.target.value)}
//                         required
//                       />
//                     </div>
//                   </InputWrapper>
//                 </Col>
//               </Row>
//               <div
//                 style={{
//                   textAlign: "center",
//                   marginTop: "2%",
//                   display: "flex",
//                   flexDirection: "row",
//                   justifyContent: "center",
//                 }}
//               >
//                 <button
//                   style={{
//                     color: "#6FB545",
//                     padding: "7px 24px",
//                     // paddingLeft: "15px",
//                     // paddingRight: "15px",
//                     border: "1px solid #6FB545",
//                     borderRadius: "8px",
//                     fontWeight: "600",
//                     backgroundColor: "#FAFFFA",
//                   }}
//                   onClick={cancelModal}
//                 >
//                   Discard
//                 </button>
//                 &nbsp; &nbsp;&nbsp; &nbsp;
//                 {assignTo !== "" &&
//                 priority !== "" &&
//                 ticketType !== "" &&
//                 dueDate !== "" ? (
//                   <button
//                     style={{
//                       color: "#FAFFFA",
//                       padding: "10px 27px",
//                       // paddingLeft: "15px",
//                       // paddingRight: "15px",
//                       border: "none",
//                       borderRadius: "8px",
//                       fontWeight: "600",
//                       backgroundColor: "#6FB545",
//                     }}
//                     // onClick={createAssignTicket}
//                     disabled={
//                       assignTo === "" &&
//                       priority === "" &&
//                       ticketType === "" &&
//                       dueDate === ""
//                         ? true
//                         : false
//                     }
//                   >
//                     Create
//                   </button>
//                 ) : (
//                   <button
//                     style={{
//                       cursor: "no-drop",
//                       color: "#FAFFFA",
//                       padding: "10px 27px",
//                       // paddingLeft: "15px",
//                       // paddingRight: "15px",
//                       border: "none",
//                       borderRadius: "8px",
//                       fontWeight: "600",
//                       backgroundColor: "#6FB545",
//                     }}
//                     disabled={true}
//                   >
//                     Create
//                   </button>
//                 )}
//               </div>
//             </form>
//           </>
//         </Modal>
//       ) : null}
//     </>
//   );
// }
