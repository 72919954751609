// // import axios from "axios";
// // import { createBrowserHistory } from "history";
// // // import { LocalActivity } from "@mui/icons-material";

// // // export const baseUrl = "http://localhost:5002";

// // export const baseUrl = "http://localhost:5003";
// // //
// // // export const baseUrl = "http://192.168.18.41:5003";

// // export const header = {
// //   header: {
// //     "Content-Type": "application/json",
// //     "Access-Control-Allow-Origin": "*",
// //   },
// // };
// // // const instance = axios.create();

// // // instance.interceptors.request.use((config) => {
// // //   const token = localStorage.getItem("auth_key");
// // //   console.log("Token:", token);
// // //   if (token) {
// // //     config.headers["X-Auth-Key"] = token;
// // //   }

// // //   return config;
// // // });

// // const token = localStorage.getItem("auth_key");

// // // Create an Axios instance with default configurations
// // const instance = axios.create({
// //   baseURL: baseUrl, // Replace with your API's base URL
// //   // timeout: 5000, // Adjust as needed
// //   headers: {
// //     "Content-Type": "application/json",
// //     "X-Auth-Key": token, // Add the X-Auth-Key header with your token
// //   },
// // });

// // // export default instance;

// // // instance.interceptors.request.use(
// // //   (config) => {
// // //     const token = JSON.parse(sessionStorage.getItem("token"));

// // //     if (token) {
// // //       config.params = { ...config.params, token };
// // //     }

// // //     return config;
// // //   },

// // //   (error) => {
// // //     return Promise.reject(error);
// // //   }
// // // );

// // // instance.interceptors.response.use(
// // //   (response) => {
// // //     return response;
// // //   },
// // //   async function (error) {
// // //     console.clear();
// // //     console.log("logout error in axios", error?.response?.status);
// // //     if (error?.response?.status === 401) {
// // //       removeStorage();
// // //       createBrowserHistory().push("/");
// // //       window.location.reload();
// // //     } else {
// // //       return error;
// // //     }
// // //   }
// // // );
// // instance.interceptors.response.use(
// //   (response) => {
// //     return response;
// //   }
// //   // async function(error) {
// //   //  console.clear();
// //   //   console.log("logout error in axios", error?.response?.status);
// //   //   if (error?.response?.status === 401) {
// //   //     removeStorage();
// //   //     createBrowserHistory().push("/");
// //   //     window.location.reload();
// //   //   } else if (error.response) {
// //   //     // handle other response errors here
// //   //     return Promise.reject(error.response);
// //   //   } else {
// //   //     // handle non-response errors here
// //   //     return Promise.reject(error);
// //   //   }
// //   // }
// // );

// // export const removeStorage = () => {
// //   localStorage.removeItem("auth_key");
// //   localStorage.removeItem("role");
// //   localStorage.removeItem("name");
// //   localStorage.removeItem("id");
// //   localStorage.removeItem("organization_id");
// // };

// // export default instance;

// import axios from "axios";
// import { createBrowserHistory } from "history";

// // export const baseUrl = "http://localhost:5003";
// export const baseUrl = "http://192.168.18.41:5003";

// // Function to get the authentication token from localStorage
// const getToken = () => {
//   const token = localStorage.getItem("auth_key");
//   return token || "";
// };

// export const header = {
//   "Content-Type": "application/json",
//   "Access-Control-Allow-Origin": "*",
// };

// // Create an Axios   // const getContracts = async () => {
// //   try {
// //     const response = await instance.get(baseUrl + "/getallcontract");
// //     console.log("contact>>>>>>>>", response.data);
// //     setContact(response.data);
// //   } catch (error) {
// //     console.error("Error fetching data:", error);
// //   }
// // };instance with default configurations
// const instance = axios.create({
//   baseURL: baseUrl,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// // Add a request interceptor to include the authentication token
// instance.interceptors.request.use(
//   async (config) => {
//     const token = getToken();
//     config.headers["X-Auth-Key"] = token;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor to handle 401 errors and other response errors
// instance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async function(error) {
//     // console.clear();
//     // console.log("logout error in axios", error?.response?.status);
//     // if (error?.response?.status === 401) {
//     //   removeStorage();
//     //   createBrowserHistory().push("/");
//     //   window.location.reload();
//     // } else if (error.response) {
//     //   // Handle other response errors here
//     //   return Promise.reject(error.response);
//     // } else {
//     //   // Handle non-response errors here
//     //   return Promise.reject(error);
//     // }
//   }
// );

// // Function to remove items from localStorage
// export const removeStorage = () => {
//   localStorage.removeItem("auth_key");
//   localStorage.removeItem("role");
//   localStorage.removeItem("name");
//   localStorage.removeItem("id");
//   localStorage.removeItem("organization_id");
// };

// export default instance;
import axios from "axios";
import { createBrowserHistory } from "history";

// Define your base URL
// export const baseUrl = "https://esupportportalbackend.extravis.co";
// export const baseUrl = "http://localhost:5003";
// export const baseUrl = "https://18.168.188.31:5003";
// export const baseUrl = "http://192.168.10.5:5003";
export const baseUrl = "https://esupportportalbackend.extravis.co";
// Function to get the authentication token from localStorage
const getToken = () => {
  const token = localStorage.getItem("auth_key");
  return token || "";
};

// Create an Axios instance with default configurations
const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor to include the authentication token
instance.interceptors.request.use(
  async (config) => {
    const token = getToken();
    config.headers["X-Auth-Key"] = token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const header = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

// Add a response interceptor to handle 401 errors and other response errors
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function(error) {
    if (error.response && error.response.status === 401) {
      // Handle the case where the token is invalid or expired
      // You can implement your logic here, e.g., clearing storage and redirecting
      removeStorage();
      createBrowserHistory().push("/");
      window.location.reload();
    } else {
      // Handle other response errors here
      return Promise.reject(error);
    }
  }
);

// Function to remove items from localStorage
export const removeStorage = () => {
  localStorage.removeItem("auth_key");
  localStorage.removeItem("role");
  localStorage.removeItem("name");
  localStorage.removeItem("id");
  localStorage.removeItem("organization_id");
};

export default instance;
