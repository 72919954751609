import React from "react";
import "./TicketHistoryDetail.css";
import img from "../../Assests/images/ticketdetailimg.png";

function TicketHistoryDetail(props) {
  return (
    <>
      <div style={{ display: "flex", gap: "30px", paddingBottom: "20px" }}>
        <div className="Line">
          <div className="img-wrapper">
            <img src={img} />
          </div>
          <div className="line-name"></div>
        </div>
        <div>
          <p className="ticket-title" style={{ paddingTop: "5px" }}>
            {props.subject}
            {/* <span className="title-span">#INC-5168</span> */}
          </p>

          <p className="ticket-created-history" style={{ paddingTop: "5px" }}>
            {props.ticketdate}
          </p>
          <p className="ticket-created-history" style={{ paddingTop: "5px" }}>
            Status :{" "}
            <span
              style={{
                // border: "2px solid ",
                borderRadius: "25px",
                paddingRight: "8px",
                paddingBottom: "1px",
                backgroundColor: " #E4FFC0",
                color: "#7DBF25",
              }}
            >
              &nbsp;&nbsp;{props.status}
            </span>
          </p>
          <div
            style={{
              display: "flex",
              paddingTop: "5px",
            }}
            className="ticket-created-history"
          >
            <p>Agents :</p>
            <p> &nbsp;&nbsp;{props.agent}</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TicketHistoryDetail;
