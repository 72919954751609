import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Icons, LinkText, AllOrgLinks } from "../styles/sideNav.styles";
import { Button, Tooltip, Dropdown, Space } from "antd";
// import Fab from "@mui/material/Fab";
// import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import "../css/topbar.css";
import TrashImage from "./../../images/trash4.svg";
import Trash2 from "./../../images/trash2.svg";
import Home2 from "./../../images/home-2.svg";
import reports from "./../../../Assests/images/reports.svg";
import Home2G from "./../../images/home-2-green.svg";
import MembersImage from "./../../images/members.svg";
import Members2G from "./../../images/members-2-green.svg";
import Report2 from "./../../images/timer.svg";
import Report2G from "./../../images/timer-2.svg";
import Project2 from "./../../images/project-2.svg";
import Project2G from "./../../images/project-2g.svg";
import chat2 from "./../../images/message.svg";
import setting2 from "./../../images/setting-2.svg";
import setting2g from "./../../images/setting-2g.svg";
import bell from "./../../images/bell.svg";
import logout from "./../../images/logout.svg";
import dp from "./../../../Assests/images/dp.png";
import search from "./../../images/search-normal.svg";
import logo from "./../../images/dp.svg";
import elogo from "../../../Assests/images/Subtract.png";
import cross from "../../../Assests/images/cross.png";
import contractlogo from "../../../Assests/images/contractlogo.png";
import customer from "../../../Assests/images/customer.png";
import ticketnew from "../../../Assests/images/ticketnew.png";
import Home from "../../../Assests/images/home.png";
import plus from "../../../Assests/images/plus.svg";
import folder from "../../../Assests/images/folder.png";
import info from "../../../Assests/images/info.png";
import shop from "../../../Assests/images/shop.png";
import CreatTicket from "./createTicket";
import user from "../../../Assests/images/user.svg";
import { StyledButton } from "../../../Components/button.styles.js";
import {
  StyledMainDiv,
  StyledSideBar,
  TopBarMain,
  TopLeft,
  TopRight,
  Header,
  ToggleIcon,
} from "../../../Pages/organization/main.styles.js";

import { Row, Col } from "antd";

const Index = ({ children }) => {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = window.scrollY;
  //     const navbarHeight = document.querySelector(".navbar").offsetHeight;

  //     if (scrollTop > navbarHeight) {
  //       setIsNavbarFixed(true);
  //     } else {
  //       setIsNavbarFixed(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const location = useLocation();
  const LogOut = () => {
    localStorage.removeItem("auth_key");
    localStorage.removeItem("name");
    localStorage.removeItem("role");
    localStorage.removeItem("id");
    localStorage.removeItem("organization_id");
    window.location.href = "/login";
  };

  let menuItems =
    localStorage.getItem("role") === "Admin"
      ? [
          {
            path: "/",
            name: "Home",
            icon: <img src={Home} />,
          },
          {
            path: "/tickets",
            name: "Tickets",
            icon: <img src={ticketnew} />,
          },
          {
            path: "/contracts",
            name: "Contracts",
            icon: <img src={contractlogo} />,
          },
          {
            path: "/customers",
            name: "Customers",
            icon: <img src={customer} />,
          },
          {
            path: "/users",
            name: "Users",
            icon: <img src={user} />,
          },
          // {
          //   path: "/reports",
          //   name: "Reports",
          //   icon: <img src={reports} />,
          // },

          // {
          //   path: "/Newticket",
          //   name: "New Ticket",
          //   icon: <img src={ticketnew} />,
          // },

          ////////////////////////////////////////////

          // {
          //   path: "/members",
          //   name: "Members",
          //   icon: <img src={MembersImage} />,
          // },
          // {
          //   path: "/projects",
          //   name: "Projects",
          //   icon: <img src={Project2} />,
          // },
          // {
          //   path: "/trash-can",
          //   name: "Trash Can",
          //   icon: <img src={TrashImage} />,
          // },
          // {
          //   path: "/chat",
          //   name: "Chat",
          //   icon: <img src={chat2} />,
          //   // icon: <SmsOutlinedIcon />,
          // },
          // {
          //   path: "/settings",
          //   name: "Settings",
          //   icon: <img src={setting2} />,
          // },
        ]
      : localStorage.getItem("role") === "User"
      ? [
          // {
          //   path: "/",
          //   name: "Home",
          //   icon: <img src={Home2G} />,
          // },
          {
            path: "/tickets",
            name: "Tickets",
            icon: <img src={ticketnew} />,
          },
          {
            path: "/contracts",
            name: "Contracts",
            icon: <img src={contractlogo} />,
          },
          {
            path: "/customers",
            name: "Customers",
            icon: <img src={customer} />,
          },
          // {
          //   path: "/trash-can",
          //   name: "Trash Can",
          //   icon: <img src={TrashImage} />,
          // },
          // {
          //   path: "/chat",
          //   name: "Chat",
          //   icon: <img src={chat2} />,
          //   // icon: <SmsOutlinedIcon />,
          // },
          // {
          //   path: "/settings",
          //   name: "Settings",
          //   icon: <img src={setting2} />,
          // },
        ]
      : localStorage.getItem("role") === "Manager"
      ? [
          {
            path: "/",
            name: "Home",
            icon: <img src={Home2} />,
          },
          {
            path: "/reports",
            name: "Reports",
            icon: <img src={Report2} />,
          },

          // {
          //   path: "/members",
          //   name: "Members",
          //   icon: <img src={MembersImage} />,
          // },
          // {
          //   path: "/projects",
          //   name: "Projects",
          //   icon: <img src={Project2} />,
          // },
          // {
          //   path: "/trash-can",
          //   name: "Trash Can",
          //   icon: <img src={TrashImage} />,
          // },
          // {
          //   path: "/chat",
          //   name: "Chat",
          //   icon: <img src={chat2} />,
          //   // icon: <SmsOutlinedIcon />,
          // },
          // {
          //   path: "/settings",
          //   name: "Settings",
          //   icon: <img src={setting2} />,
          // },
        ]
      : null;

  const [isOpen, setIsOpen] = useState(false);
  const [ifOpen, setIfOpen] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [iscreateTicketModalVisible, setIscreateTicketModalVisible] = useState(
    false
  );

  const handleTabClick = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setIsTransitioning(false);
    }, 500);
  };
  const toggleDropdown = () => {
    setIfOpen(!isOpen);
  };

  // const toggleSidebar = () => {
  //   setIsOpen(!isOpen);
  // };

  const showModal = () => {
    setIscreateTicketModalVisible(true);
  };

  const cancelModal = () => {
    setIscreateTicketModalVisible(false);
  };
  return (
    <div style={{ width: "100%" }}>
      <StyledSideBar>
        <Header>
          <img src={elogo} />
        </Header>

        <div
          style={{
            paddingTop: "5px",
            diaplay: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            borderRight: "1px solid #E3E3E3",
            height: "100%",
          }}
        >
          {menuItems.map((item, index) => (
            <AllOrgLinks
              to={item.path}
              key={index}
              activeStyle
              onClick={handleTabClick}
            >
              <Tooltip color="#229849" placement="rightTop" title={item.name}>
                <Icons
                  style={{
                    marginTop: "10px",
                  }}
                >
                  {item.icon}
                </Icons>
              </Tooltip>
              <LinkText
                style={{
                  display: isOpen ? "block" : "none",
                  paddingTop: "10px",
                }}
              >
                {item.name}
              </LinkText>
            </AllOrgLinks>
          ))}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30%",
            }}
          ></div>
        </div>
      </StyledSideBar>

      <div style={{ width: "100%" }}>
        <div
          className={`navbar ${isNavbarFixed ? "fixed" : ""}`}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #E3E3E3",
            marginLeft: "61px",
            padding: "14px 10px 14px 10px",
            marginBottom: "17px",
            transition: "all 0.3s ease",
          }}
        >
          <div
            style={{
              display: "flex",
              paddingLeft: "20px",
            }}
          >
            {location.pathname === "/" ? (
              <>
                <p
                  style={{
                    color: "#000000",
                    fontSize: "20px",
                    fontWeight: 600,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={Home} width="24px" height="24px" /> &nbsp; Home
                </p>
              </>
            ) : null}
            {location.pathname === "/tickets" ? (
              <>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={ticketnew} width={24} height={24} /> &nbsp; Tickets
                </p>
              </>
            ) : null}
            {location.pathname === "/contracts" ? (
              <>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={contractlogo} width={24} height={24} /> &nbsp;
                  Contracts
                </p>
              </>
            ) : null}
            {location.pathname === "/users" ? (
              <>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={contractlogo} width={24} height={24} /> &nbsp; Users
                </p>
              </>
            ) : null}
            {location.pathname === "/addcontract" ? (
              <>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={contractlogo} width={24} height={24} /> &nbsp; Add
                  Contracts
                </p>
              </>
            ) : null}
            {location.pathname === "/Newticket" ? (
              <>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={ticketnew} width={24} height={24} /> &nbsp; Tickets
                </p>
              </>
            ) : null}

            {location.pathname === "/customers" ? (
              <>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "500",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: 0,
                  }}
                >
                  <img src={customer} width={24} height={24} /> &nbsp; Customers
                </p>
              </>
            ) : null}

            {location.pathname === "/reports" ? (
              <>
                <p style={{ fontSize: "18px", fontWeight: "500" }}>
                  <img src={Report2G} /> &nbsp; Reports
                </p>
              </>
            ) : null}
            {location.pathname === "/trash-can" ? (
              <>
                <p style={{ fontSize: "18px", fontWeight: "500" }}>
                  <img src={Trash2} /> &nbsp; Trash Can
                </p>
              </>
            ) : null}
            {location.pathname === "/members" ? (
              <>
                <p style={{ fontSize: "18px", fontWeight: "500" }}>
                  <img src={Members2G} /> &nbsp; Members
                </p>
              </>
            ) : null}
            {location.pathname === "/projects" ? (
              <>
                <p style={{ fontSize: "18px", fontWeight: "500" }}>
                  <img src={Project2G} /> &nbsp; Projects
                </p>
              </>
            ) : null}
            {location.pathname === "/chat" ? (
              <>
                <p style={{ fontSize: "18px", fontWeight: "500" }}>
                  <img src={chat2} /> &nbsp; Chat
                </p>
              </>
            ) : null}
            {location.pathname === "/settings" ? (
              <>
                <p style={{ fontSize: "18px", fontWeight: "500" }}>
                  <img src={setting2g} /> &nbsp; Settings
                </p>
              </>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              // justifyContent: "end",
              alignItems: "center",
              gap: "15px",
              paddingRight: "20px",
            }}
          >
            {/* {localStorage.getItem("role") === "Admin" && (
              <Link to="/addvendor">
                <Button className="import_btn">Add Customer</Button>
              </Link>
            )} */}
            <img src={logout} style={{ cursor: "pointer" }} onClick={LogOut} />
            <img src={dp} width={30} height={30} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "400",
                  // fontFamily: "Inter",
                  color: "#919191",
                }}
              >
                {localStorage.getItem("name")}
              </div>
              <div
                style={{
                  fontSize: "12px",

                  fontWeight: "500",
                  // fontFamily: "Inter",,
                  color: "#000000",
                }}
              >
                {localStorage.getItem("role")}
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            padding: "0px",
            marginLeft: "65px",
            marginTop: "15px",
            // border: "2px solid green",
            width: "95%",
            // maxWidth: "1200px",
            // minWidth: "80%",
            // paddingLeft: "25px",
            // paddingRight: "10px",
            // border: "2px solid red",
            // width: "75%",
            minHeight: "100vh",
            // display: "flex",
            // flexDirection: "column",
            // justifyContent: "end",
            // position: "relative",
          }}
        >
          {children}
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Index;
