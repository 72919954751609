// import React, { useEffect, useState } from "react";
// import ReactECharts from "echarts-for-react";
// import axios from "axios";
// import { baseUrl } from "../utils/axios";

// function Ticketchart() {
//   const [ticketStatusGraph, setTicketStatusGraph] = useState([]);

//   // API
//   const getTicketStatusGraph = async () => {
//     try {
//       const response = await axios.get(baseUrl + "/UnresolvedTicketByGroup");
//       console.log(
//         "priority ticket status>>>>>>>>",
//         response.data.ticket_counts
//       );
//       setTicketStatusGraph(response.data.ticket_counts);
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     getTicketStatusGraph();
//   }, []);

//   // Extract custom colors for different organizations
//   const customColors = ["#FEA800", "#DA0404", "#EBC606"];

//   // Extract organization names and ticket counts from ticketStatusGraph
//   const organizationNames = ticketStatusGraph
//     .filter((item) => item.organization_name !== "CompanyToRemove") // Replace "CompanyToRemove" with the actual company name to remove
//     .map((item) => item.organization_name);

//   const ticketCounts = ticketStatusGraph
//     .filter((item) => item.organization_name !== "CompanyToRemove") // Replace "CompanyToRemove" with the actual company name to remove
//     .map((item) => item.ticket_count);

//   // Generate series data for the chart with reduced bar width
//   const seriesData = organizationNames.map((name, index) => ({
//     name,
//     type: "bar",
//     emphasis: {
//       focus: "series",
//     },
//     itemStyle: {
//       color: customColors[index % customColors.length], // Use custom colors in a loop
//       barWidth: 5, // Set the width of the bars in pixels (adjust as needed)
//     },
//     data: [ticketCounts[index]],
//   }));

//   // Define the options for the ECharts chart
//   const options = {
//     title: {
//       text: "No. of tickets per status",
//       textStyle: {
//         color: "#6C6C6C", // Set the title font color to #6C6C6C
//       },
//     },
//     tooltip: {
//       trigger: "axis",
//       axisPointer: {
//         type: "shadow",
//       },
//     },
//     grid: {
//       left: "3%",
//       right: "4%",
//       bottom: "3%",
//       containLabel: true,
//     },
//     xAxis: [
//       {
//         type: "category",
//         data: organizationNames,
//         axisLabel: {
//           color: "#000000", // Set font color to black
//           fontSize: 16, // Set font size to 16px
//           fontWeight: 500, // Set font weight to 500
//         },
//       },
//     ],
//     yAxis: [
//       {
//         type: "value",
//         position: "right", // Set the position to right
//         axisLabel: {
//           color: "#000000", // Set font color to black
//           fontSize: 16, // Set font size to 16px
//           fontWeight: 500, // Set font weight to 500
//         },
//       },
//     ],
//     series: seriesData, // Use the series data with custom colors and specified barWidth
//   };

//   return (
//     <div style={{ width: "100%", height: "330px", paddingTop: "20px" }}>
//       {/* Use the ReactECharts component to render the chart */}
//       <ReactECharts option={options} style={{ height: "100%" }} />
//     </div>
//   );
// }

// export default Ticketchart;

import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import axios from "axios";
import { baseUrl } from "../utils/axios";
import instance from "../utils/axios";

function Ticketchart() {
  const [ticketStatusGraph, setTicketStatusGraph] = useState([]);

  // API
  const getTicketStatusGraph = async () => {
    try {
      const response = await instance.get(baseUrl + "/UnresolvedTicketByGroup");
      setTicketStatusGraph(response.data.ticket_counts);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getTicketStatusGraph();
  }, []);

  // Extract custom colors for different organizations
  const customColors = ["#FEA800", "#DA0404", "#EBC606"];

  // Extract organization names and ticket counts from ticketStatusGraph
  const organizationData = ticketStatusGraph
    .filter((item) => item.organization_name !== "CompanyToRemove") // Replace "CompanyToRemove" with the actual company name to remove
    .map((item, index) => ({
      name: item.organization_name,
      value: item.ticket_count,
      itemStyle: {
        color: customColors[index % customColors.length], // Use custom colors in a loop
      },
    }));

  // Define the options for the ECharts chart
  const options = {
    title: {
      text: "No. of tickets per Group",
      textStyle: {
        color: "#6C6C6C",
        fontSize: "16px",
        fontWeight: 400,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: organizationData.map((item) => item.name), // Use organization names as data
        axisLabel: {
          color: "#787486", // Set font color to black
          fontSize: "14px", // Set font size to 16px
          fontWeight: 500, // Set font weight to 500
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        position: "right", // Set the position to right
        axisLabel: {
          color: "#000000", // Set font color to black
          fontSize: 16, // Set font size to 16px
          fontWeight: 500, // Set font weight to 500
        },
      },
    ],
    series: [
      {
        name: "No. of Tickets",
        type: "bar",
        barWidth: "25",
        // radius: "30px",
        data: organizationData.map((item) => item.value), // Use ticket counts as data
        itemStyle: {
          color: (params) => organizationData[params.dataIndex].itemStyle.color, // Set bar colors based on custom colors
        },
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "330px", padding: "25px 15px" }}>
      <ReactECharts option={options} style={{ height: "100%" }} />
    </div>
  );
}

export default Ticketchart;
