// import React, { useEffect, useState } from "react";

// import ReactECharts from "echarts-for-react"; // Import ReactECharts

// import axios from "axios";

// import { baseUrl } from "../utils/axios";

// function Unresolvedticketchart() {

//   const [unresolvedTicketGraph, setUnresolvedTicketGraph] = useState([]);

//   // API

//   const getUnresolvedTicket = async () => {

//     try {

//       const response = await axios.get(

//         baseUrl + "/GetTicketCountByGroupAndStatusLineBar"

//       );

//       console.log("donutssssssssssssss>>>>>>>>", response.data);

//       setUnresolvedTicketGraph(response.data);

//     } catch (error) {

//       console.error("Error fetching data:", error);

//     }

//   };

//   useEffect(() => {

//     getUnresolvedTicket();

//   }, []);

//   // Extract data from unresolvedTicketGraph

//   const data = unresolvedTicketGraph.map((item) => ({

//     value: item.ticket_count,

//     name: item.status,

//   }));

//   // Define the options for the ECharts chart

//   const options = {

//     title: {

//       text: "All unresolved tickets by Status",

//       textStyle: {

//         color: "#6C6C6C", // Set the title font color to #6C6C6C

//       },

//     },

//     tooltip: {

//       trigger: "item",

//     },

//     series: [

//       {

//         name: "Access From",

//         type: "pie",

//         radius: ["40%", "70%"],

//         avoidLabelOverlap: false,

//         label: {

//           show: false,

//           position: "center",

//         },

//         emphasis: {

//           label: {

//             show: true,

//             fontSize: 40,

//             fontWeight: "bold",

//           },

//         },

//         labelLine: {

//           show: false,

//         },

//         data: data, // Use data from state

//       },

//     ],

//   };

//   return (

//     <div style={{ width: "100%", height: "330px", paddingTop: "20px" }}>

//       {/* Use the ReactECharts component to render the chart */}

//       <ReactECharts option={options} style={{ height: "100%" }} />

//     </div>

//   );

// }

// export default Unresolvedticketchart;

// import React, { useEffect, useState } from "react";

// import ReactECharts from "echarts-for-react";

// import axios from "axios";

// import { baseUrl } from "../utils/axios";

// function Unresolvedticketchart() {

//   const [unresolvedTicketGraph, setUnresolvedTicketGraph] = useState([]);

//   // API

//   const getUnresolvedTicket = async () => {

//     try {

//       const response = await axios.get(

//         baseUrl + "/GetTicketCountByGroupAndStatusLineBar"

//       );

//       console.log("donutssssssssssssss>>>>>>>>", response.data);

//       setUnresolvedTicketGraph(response.data);

//     } catch (error) {

//       console.error("Error fetching data:", error);

//     }

//   };

//   useEffect(() => {

//     getUnresolvedTicket();

//   }, []);

//   // Extract data from unresolvedTicketGraph

//   const data = unresolvedTicketGraph.map((item) => ({

//     value: item.ticket_count,

//     name: item.status,

//   }));

//   // Define the custom colors

//   const colors = ["#F45151", "#527855", "#FEE154", "#A3DB58"];

//   // Define the options for the ECharts chart

//   const options = {

//     title: {

//       text: "All unresolved tickets by Status",

//       textStyle: {

//         color: "#6C6C6C",

//       },

//     },

//     tooltip: {

//       trigger: "item",

//     },

//     color: colors, // Use the custom colors for the entire chart

//     series: [

//       {

//         name: "Access From",

//         type: "pie",

//         radius: ["40%", "70%"],

//         avoidLabelOverlap: false,

//         label: {

//           show: false,

//           position: "center",

//         },

//         emphasis: {

//           label: {

//             show: true,

//             fontSize: 40,

//             fontWeight: "bold",

//           },

//         },

//         labelLine: {

//           show: false,

//         },

//         data: data,

//       },

//     ],

//   };

//   return (

//     <div style={{ width: "100%", height: "330px", paddingTop: "20px" }}>

//       <ReactECharts option={options} style={{ height: "100%" }} />

//     </div>

//   );

// }

// export default Unresolvedticketchart;

import React, { useEffect, useState } from "react";

import ReactECharts from "echarts-for-react";

import axios from "axios";

import { baseUrl } from "../utils/axios";

import instance from "../utils/axios";

function Unresolvedticketchart() {
  const [unresolvedTicketGraph, setUnresolvedTicketGraph] = useState([]);

  // API

  const getUnresolvedTicket = async () => {
    try {
      const response = await instance.get(baseUrl + "/getTicketCountPieGraph");

      // Add color property to each data item

      const coloredData = response.data.map((item, index) => ({
        value: item.value,

        name: item.name,

        itemStyle: {
          color: ["#3ac785", "#dc3938", "#68BB59", "#117e17", "#FFD300"][index], // Set color based on index
        },
      }));

      setUnresolvedTicketGraph(coloredData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getUnresolvedTicket();
  }, []);

  // Define the options for the ECharts chart

  const options = {
    title: {
      text: "All unresolved tickets by Status",
      textStyle: {
        color: "#6C6C6C",
        fontWeight: 600,
        fontSize: "16px",
      },
    },

    tooltip: {
      trigger: "item",
    },

    series: [
      {
        name: "Access From",
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: "center",
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 16,
            formatter: "{b}: {c} ({d}%)",
          },
        },
        labelLine: {
          normal: {
            show: true,
            length: 10,
          },
        },

        data: unresolvedTicketGraph,
      },
    ],
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        width: "100%",
        height: "100%",
        paddingTop: "20px",
      }}
    >
      <ReactECharts
        option={options}
        style={{ height: "100%", width: "100%", paddingLeft: "10px" }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "8px",
        }}
      >
        <ul style={{ padding: "0px" }}>
          {unresolvedTicketGraph.map((item) => (
            <li
              key={item.name}
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  paddingTop: "5%",
                  fontSize: "12px",
                  fontWeight: "700",
                  color: "#6C6C6C",
                }}
              >
                <div
                  style={{
                    content: "",
                    width: "12px",
                    height: "12px",
                    borderRadius: "100%",
                    background: item.itemStyle.color,
                  }}
                ></div>
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: 700,
                    color: "#414142",
                  }}
                >
                  {item.value}
                </span>
                <span
                  style={{
                    color: "#6C6C6C",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {item.name}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Unresolvedticketchart;
