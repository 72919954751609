import styled from "styled-components";

export const StyledButton=styled.button`
display:flex;
flex-direction: row;
justify-content: center;
align-items:center;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
/* color: #FFFFFF; */
/* background: #2D482F; */
border-radius: 10px;
padding: 10px 20px;
/* border: none; */
gap:5px;
background: #FFFFFF;
border:1px solid #6FB545;
color: #6FB545;
/* color: ${(props) => (props.active ? "#E50F0F" : "#333333")}; */
&:hover{
    cursor: pointer;
    background: #6FB545;  
    border: none;
    color: #FFFFFF;
}

&:focus{
    cursor: pointer;
    background: #6FB545;  
    border: none;
    color: #FFFFFF;
}
`;