import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import axios from "axios";
import instance, { baseUrl } from "../utils/axios";
import { Row, Col } from "antd";

function BarChart() {
  const [contractGraph, setContractGraph] = useState([]);

  const getTicketPriorityGraph = async () => {
    try {
      const response = await instance.get(baseUrl + "/AllContractCounts");
      setContractGraph(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getTicketPriorityGraph();
  }, []);

  const colors = {
    Expired: "#DA0404",
    Active: "#139C08",
    Pending: "#FFD700",
    "all contracts": "#FEA800",
  };

  const categories = contractGraph.map((item) => item.name);
  const values = contractGraph.map((item) => item.value);

  const options = {
    title: {
      text: "No. of contracts per Status",
      textStyle: {
        color: "#6C6C6C",
        fontSize: "16px",
        fontWeight: 400,
      },
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3  %",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        data: categories,
        axisLabel: {
          color: "#787486",
          fontSize: "14px",
          fontWeight: 500,
          interval: 0,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        position: "right",
        axisLabel: {
          color: "#000000",
          fontSize: "16px",
          fontWeight: 500,
        },
      },
    ],
    series: [
      {
        name: "No. of Contracts",
        type: "bar",
        barWidth: "20%",
        data: values,
        itemStyle: {
          color: (params) => colors[params.name],
        },
      },
    ],
  };

  return (
    <div style={{ width: "100%", paddingTop: "20px" }}>
      <ReactECharts
        option={options}
        style={{ height: "330px", width: "100%" }}
      />
    </div>
  );
}

export default BarChart;
