import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";

export const StyledSidebar = styled.nav`
  width: 200px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  background: #2d482f;
  position: fixed;
  z-index: 1;
`;
export const StyledNavHeading = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  margin-top: 10%;
`;
export const StyledLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 50%; */
  width: 100%;
  /* border:1px solid pink; */
  margin-top: 20%;
  gap: 10px;
`;
export const StyledNavLink = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  width: 100%;
  /* height: 15%; */
  gap: 25px;
  &:hover {
    background: linear-gradient(
      270deg,
      rgba(217, 217, 217, 0.25) -61.79%,
      rgba(217, 217, 217, 0) 120.05%
    );
    border-radius: 8px 0px 0px 8px;
    color: #ffffff;
  }
`;
export const StyledNavLinkImage = styled.img`
  /* height: 17px; */
  width: 17px;
  margin-top: -3px;
`;

export const MainContainer = styled.div`
  display: flex;
`;
export const Sidebar = styled.div`
  background: #2d482f;
  color: #fff;
  /* height: 100%; */
  width: 200px;
  transition: all 0.5s;
  position: fixed;
  z-index: 1;
`;
export const TopSection = styled.div`
  display: flex;
  color: #fff;
  align-items: center;
  padding: 20px 15px;
  margin-bottom: 15px;
`;
export const Logo = styled.h1`
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  width: 220px;
  color: #ffffff;
`;
export const BarDiv = styled.div`
  display: flex;
  font-size: 25px;
  margin-left: 50px;  
  transition: all 0.5s;
`;
export const AllLinks = styled(Link)`
  display: flex;
  /* justify-content: center; */
  text-decoration: none;
  padding: 10px 15px;
  padding-left: 70px;
  color: #fff;
  gap: 15px;
  margin-bottom: 5%;
  transition: all 0.5s;

  &:hover {
    background: linear-gradient(
      270deg,
      rgba(217, 217, 217, 0.25) -61.79%,
      rgba(217, 217, 217, 0) 120.05%
    );
    color: #fff;
    transition: all 0.5s;
  }
  &.active {
    background: linear-gradient(
      270deg,
      rgba(217, 217, 217, 0.25) -61.79%,
      rgba(217, 217, 217, 0) 120.05%
    );
    color: #fff;
    /* transition: all 0.5s; */
  }
`;
export const StyledMain = styled.div`
  width: 100%;
  /* height: 100vh; */
  padding-left: 25px;
  padding-right: 25px;
  overflow-y: scroll;
  
`
export const AllOrgLinks = styled(Link)`
/* border:1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-left: 5%;
  padding-top: 4%;
  padding-bottom: 4%;
  gap:15px;
  margin-bottom: 5%;
  color: #FAFFFA;
  transition: all 0.5s;


  &:hover {
    background: linear-gradient(270deg, rgba(246, 246, 246, 0.59) -61.79%, rgba(232, 232, 232, 0.303227) 26.59%, rgba(217, 217, 217, 0) 120.05%);
    border-radius: 8px 0px 0px 8px;
    color: #fff;
    transition: all 0.5s;
  }
  &.active {
    background: linear-gradient(270deg, rgba(246, 246, 246, 0.59) -61.79%, rgba(232, 232, 232, 0.303227) 26.59%, rgba(217, 217, 217, 0) 120.05%);
    border-radius: 8px 2px 2px 8px;
    color: #fff;
    border-right: 4px solid #fff;
    transition: all 0.5s;
  }
`;
export const Icons = styled.div`
  font-size: 16px;
  font-weight: 600;
`;
export const LinkText = styled.div`
 font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14px;
color: #000000;
`;
