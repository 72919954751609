import React, { useEffect, useState } from "react";

import {
  DownloadOutlined,
  UploadOutlined,
  LoadingOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { Button, Modal, Spin, Table, message } from "antd";
import { useNavigate } from "react-router-dom";
import instance, { baseUrl } from "../../utils/axios";
import edit from "../../Assests/images/edit.png";
import "./customers.css";

const CustomerEditModal = (props) => {
  const { isModalOpen, setIsModalOpen, customerData, getAllCustomers } = props;

  const [customer, setCustomer] = useState({
    name: "",
    phone: "",
    mobile: "",
    email: "",
    country: "",
    state: "",
    address: "",
    description: "",
  });

  useEffect(() => {
    if (customerData) {
      setCustomer({
        name: customerData.vendor_name,
        phone: customerData.vendor_phone,
        mobile: customerData.vendor_mobile,
        email: customerData.vendor_email,
        country: customerData.vendor_country,
        state: customerData.vendor_state,
      });
    }
  }, [customerData]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleInput = (e) => {
    setCustomer({
      ...customer,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    instance
      .put(`${baseUrl}/updateVendor/${customerData.vendor_id}`, customer)
      .then((res) => {
        message.success(res.data);
        getAllCustomers();
        handleOk();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <Modal
      title="Edit Customer"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      style={{
        top: 20,
      }}
    >
      <form className="edit-customer-model" onSubmit={handleSubmit}>
        <article>
          <label> Customer Name</label>
          <input
            type="text"
            placeholder="Customer Name"
            value={customer.name}
            name="name"
            onChange={handleInput}
          />
        </article>
        <article>
          <label>Phone</label>
          <input
            type="number"
            placeholder="Phone"
            value={customer.phone}
            name="phone"
            onChange={handleInput}
          />
        </article>
        <article>
          <label>Mobile</label>
          <input
            type="number"
            placeholder="Mobile"
            value={customer.mobile}
            name="mobile"
            onChange={handleInput}
          />
        </article>
        <article>
          <label>Email</label>
          <input
            type="email"
            placeholder="Email"
            value={customer.email}
            name="email"
            onChange={handleInput}
          />
        </article>
        <article>
          <label>Country</label>
          <input
            type="text"
            placeholder="Country"
            value={customer.country}
            name="country"
            onChange={handleInput}
          />
        </article>
        <article>
          <label>State</label>
          <input
            type="text"
            placeholder="State"
            value={customer.state}
            name="state"
            onChange={handleInput}
          />
        </article>
        <article className="form-button-wrapper">
          <button type="button" className="cancel-bton" onClick={handleCancel}>
            Cancel
          </button>
          <button className="update-btn" type="submit">Update</button>
        </article>
      </form>
    </Modal>
  );
};

function Customers() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const navigate = useNavigate();

  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    // Check if the stored role is "Admin"
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);

  useEffect(() => {
    getAllCustomers();
  }, []);

  const getAllCustomers = async () => {
    setLoading(true);
    instance
      .get(`${baseUrl}/getallvendorsdetails`)
      .then((res) => {
        setLoading(false);
        setVendors(res.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const exportToCSV = () => {
    const csvData = selectedRowData.map((item) => ({
      "Customer Name": item.vendor_name || "",
      Phone: item.vendor_phone || "",
      Mobile: item.vendor_mobile || "",
      Email: item.vendor_email || "", // Vendor Email to Customer Email
      Type: item.type || "",
      Country: item.vendor_country || "",
      State: item.State || "",
    }));

    const csvContent =
      "data:text/csv;charset=utf-8," +
      Object.keys(csvData[0]).join(",") +
      "\n" +
      csvData.map((row) => Object.values(row).join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "customers.csv");
    document.body.appendChild(link);
    link.click();
  };

  const onSelectChange = (newSelectedRowKeys, data) => {
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedRowData(data);
  };

  const adminColumns = [
    {
      title: (
        <span
          className="table-action"
          style={{ display: "flex", justifyContent: "center" }}
        >
          Action
        </span>
      ),
      width: 60,
      key: "action",

      fixed: "right",
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              cursor: "pointer",
            }}
          >
            <div>
              {" "}
              <img
                src={edit}
                width={14}
                height={14}
                onClick={() => {
                  setIsModalOpen(true);
                  setCustomerData(record);
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  let columns = [
    {
      title: "Customer Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
    },
    {
      title: "Phone",
      dataIndex: "vendor_phone",
      key: "vendor_phone",
    },
    {
      title: "Mobile",
      dataIndex: "vendor_mobile",
      key: "vendor_mobile",
    },
    {
      title: "Email",
      dataIndex: "vendor_email",
      key: "vendor_email",
    },
    {
      title: "Country",
      dataIndex: "vendor_country",
      key: "vendor_country",
    },
    {
      title: "State",
      dataIndex: "vendor_state",
      key: "vendor_state",
    },
    ...(userRole === "Admin" ? adminColumns : []),
  ];

  const dataSource = vendors
    ? vendors.map((vendor) => {
        vendor["key"] = vendor.vendor_id;

        return vendor;
      })
    : [];

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleAddNewButton = () => {
    navigate("/addvendor");
  };

  const handleDeleteRows = async () => {
    const ticketIdsString = selectedRowKeys.join(",");

    setLoading(true);

    await instance
      .delete(`${baseUrl}/delete_customers?ids=${ticketIdsString}`)
      .then((res) => {
        setLoading(false);
        getAllCustomers();
        setSelectedRowData([]);
        setSelectedRowKeys([]); // Add this line to clear the selected row keys

        messageApi.open({
          type: "success",
          content: "Customer Deleted Successfully",
        });
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);

        messageApi.open({
          type: "error",
          content: error.response.data.error,
        });
      });
  };

  const LoadingIndicator = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <LoadingOutlined
        style={{
          fontSize: 24,
          color: "#229849",
        }}
        spin
      />
    </div>
  );

  return (
    <section className="customer-wrapper">
      <CustomerEditModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        customerData={customerData}
        getAllCustomers={getAllCustomers}
      />
      {contextHolder}
      {localStorage.getItem("role") !== "User" && (
        <article className="action-buttons">
          {selectedRowData.length != 0 && (
            <Button
              className="delete_btn"
              icon={<DeleteOutlined />}
              disabled={selectedRowData.length == 0}
              onClick={handleDeleteRows}
            >
              Delete
            </Button>
          )}

          <article className="action-button-right">
            <button
              className="export-btn"
              onClick={exportToCSV}
              disabled={selectedRowData.length == 0}
            >
              <span>
                <UploadOutlined />
              </span>
              Export
            </button>
            <button>
              <span>
                <DownloadOutlined />
              </span>
              Import
            </button>
            <button onClick={handleAddNewButton}>Add New</button>
          </article>
        </article>
      )}

      <article className="table-wrapper">
        <Spin
          spinning={loading == true ? loading : null}
          indicator={<LoadingIndicator />}
        >
          <Table
            rowSelection={rowSelection}
            dataSource={dataSource}
            columns={columns}
          />
        </Spin>
      </article>
    </section>
  );
}

export default Customers;
