import React, { useContext, useEffect, useState } from "react";
import { baseUrl } from "./../../utils/axios/index";
import instance from "./../../utils/axios/index";
import { Link, useNavigate } from "react-router-dom";
import { Input, Button, message, Spin, Row, Col, DatePicker } from "antd";
import { Select } from "antd";

import "./newticket.css";
import AppContext from "./../../AppContext";
import dayjs from "dayjs";

const Index = () => {
  const {
    contact,
    contract,
    group,
    agent,
    vendors,
    source,
    status,
    priority,
    getAllCustomers,
    category,
  } = useContext(AppContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  // const dateFormat = "YYYY-MM-DD";

  const disabledDate = (current) => {
    return current && current < dayjs().startOf("day");
  };
  const handleOpenChange = (open) => {
    if (open) {
      setTimeout(() => {
        const calendar = document.querySelector(".ant-picker-dropdown");
        if (calendar) {
          const prevMonthButton = calendar.querySelector(
            ".ant-picker-header-prev-btn"
          );
          const prevYearButton = calendar.querySelector(
            ".ant-picker-header-super-prev-btn"
          );
          if (prevMonthButton) {
            prevMonthButton.style.display = "none";
          }
          if (prevYearButton) {
            prevYearButton.style.display = "none";
          }
        }
      }, 0);
    }
  };
  const [ticketData, setTicketData] = useState({
    contact: "",
    contract: "",
    subject: "",
    source: "",
    status: "",
    urgency: "",
    impact: "",
    priority: "",
    group: "",
    description: "",
    custom_ticket_type: "",
    email: "",
    product: "",
    agent: "",
    onsite_day: "",
    onsite_time: "",
    ticket_close_date: "",
  });

  const handleSubmitTicket = async (e) => {
    e.preventDefault();

    const requiredFields = [
      "description",
      "subject",
      "priority",
      "contact",
      "source",
      "status",
      "group",
      "agent",
      "contract",
      "category",
      "email",
      // "ticket_close_date",
    ];

    let missingRequiredFields = requiredFields.filter(
      (field) => !ticketData[field]
    );

    const missingFields = missingRequiredFields.map((str) =>
      str.replace(/group/g, "customer")
    );

    if (missingFields.length > 0) {
      message.error(`${missingFields.join(", ")} field must required.`);
      return;
    }

    if (emailError) {
      message.error("Please correct the email field before submitting.");
      return;
    }

    setIsLoading(true);
    const modifiedTicketData = {
      ...ticketData,
      contract_id: ticketData.contract,
    };

    // Remove the original contract property from modifiedTicketData
    delete modifiedTicketData.contract;

    // if (!modifiedTicketData.ticket_close_date) {
    //   delete modifiedTicketData.ticket_close_date;
    // }
    if (ticketData.status === "Closed") {
      modifiedTicketData.ticket_close_date = dayjs().format(
        "YYYY-MM-DD HH:mm:ss"
      );
    } else {
      delete modifiedTicketData.ticket_close_date;
    }
    try {
      await instance.post(`${baseUrl}/createticket`, modifiedTicketData);

      try {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        message.success("Ticket is successfully posted");
        setIsLoading(false);
        navigate("/tickets");
      } catch (error) {
        console.error("Error submitting ticket:", error);
      }

      // Reset the form data after submission
      setTicketData({
        contact: "",
        subject: "",
        source: "",
        status: "",
        urgency: "",
        impact: "",
        priority: "",
        group: "",
        contract_id: "",
        description: "",
        custom_ticket_type: "",
        email: "",
        product: "",
        agent: "",
        onsite_day: "",
        onsite_time: "",
        ticket_close_date: "",
      });
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "email") {
      // const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      if (!value.trim()) {
        setEmailError("Email is required");
      }
      // else if (!emailPattern.test(value)) {
      //   setEmailError("Invalid email address");
      // }
      else if (value.includes("@example.com")) {
        setEmailError('Email cannot contain "@example.com"');
      }
      // else if (value.split("@")[1].split(".").length > 2) {
      //   setEmailError("Invalid email format");
      // }
      else if (value.includes("..")) {
        setEmailError("Email cannot contain two consecutive dots '..'");
      } else {
        setEmailError("");
      }
    }

    setTicketData({
      ...ticketData,
      [name]: value,
    });
  };

  useEffect(() => {
    getAllCustomers();
  }, []);

  const [emailError, setEmailError] = useState("");

  const handleSelectChange = (key, value) => {
    setTicketData((ticketData) => ({
      ...ticketData,
      [key]: value,
    }));
  };

  const handleDateChange = (date) => {
    console.log("date and time", date);
    setTicketData({
      ...ticketData,
      ticket_close_date: date ? date.format(dateFormat) : "",
    });
  };

  return (
    <>
      <form
        onSubmit={handleSubmitTicket}
        style={{
          // flexBasis: "95%",
          padding: "0px 0px 0px 30px",
        }}
      >
        <div style={{ flexBasis: "100%" }}>
          <Row align="middle">
            <Col xs={24} sm={12}>
              <div
                className="newticket"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "10px",
                  // border: "2px solid red",
                }}
              >
                <h3 style={{ padding: "0px", margin: "0px" }}>New Ticket</h3>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <div
                className="newticket-button"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <Button
                  className="save-btn"
                  type="submit"
                  onClick={handleSubmitTicket}
                  disabled={isLoading}
                  style={{ backgroundColor: "#229849", color: "white" }}
                >
                  {isLoading ? "Creating..." : "Create"}
                </Button>
                <Link to="/tickets">
                  <Button className="cancel-btn" type="submit">
                    Cancel
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="type">
                  Contract <span>*</span>
                </label>
                <Select
                  value={ticketData.contract_name}
                  onChange={(value) => handleSelectChange("contract", value)}
                  style={{
                    width: "100%",
                    marginTop: "8px",
                  }}
                  options={
                    contract.length !== 0
                      ? contract.map((contract) => ({
                          value: contract.contract_id,
                          label: contract.contract_name,
                        }))
                      : []
                  }
                />
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="contractNumber">
                  Serial No. <span>(Auto Generated)</span>
                </label>
                <Input
                  readOnly={"true"}
                  disabled={"true"}
                  // disabled
                  placeholder="TK-"
                  type="text"
                  id="name"
                  name="name"
                  onChange={handleInputChange}
                />
              </div>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="contractNumber">
                  Subject <span>*</span>
                </label>
                <Input
                  style={{ marginTop: "8px" }}
                  type="text"
                  id="subject"
                  name="subject"
                  value={ticketData.subject}
                  onChange={handleInputChange}
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="type">
                  Source <span>*</span>
                </label>
                <Select
                  value={ticketData.source}
                  onChange={(value) => handleSelectChange("source", value)}
                  style={{
                    width: "100%",
                    height: "40px",
                    marginTop: "8px",
                  }}
                >
                  {source.map((source) => (
                    <Select.Option key={source.value} value={source.value}>
                      {source.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="type">
                  Status <span>*</span>
                </label>
                <Select
                  value={ticketData.status}
                  onChange={(value) => handleSelectChange("status", value)}
                  style={{
                    width: "100%",
                    height: "40px",
                    marginTop: "8px",
                  }}
                >
                  {status.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="type">
                  Priority <span>*</span>
                </label>
                <Select
                  value={ticketData.priority}
                  onChange={(value) => handleSelectChange("priority", value)}
                  style={{
                    width: "100%",
                    marginTop: "8px",
                  }}
                >
                  {priority.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="type">
                  {/* Contact to Customer Name */}
                  Customer Name<span>*</span>
                </label>
                <Select
                  value={ticketData.contact}
                  onChange={(value) => handleSelectChange("contact", value)}
                  style={{
                    width: "100%",
                    marginTop: "8px",
                  }}
                  options={contact.map((contact) => ({
                    value: contact.vendor_id,
                    label: contact.vendor_name,
                  }))}
                />
              </div>
            </Col>

            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input" style={{ position: "relative" }}>
                {isLoading && (
                  <Spin
                    tip="Submitting..."
                    size="large"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "white",
                      zIndex: 1, // Ensure the loader is above other elements
                    }}
                  />
                )}
                <label className="label" htmlFor="type">
                  Assigned To <span>*</span>
                </label>

                <Select
                  value={ticketData.agent}
                  onChange={(value) => handleSelectChange("agent", value)}
                  style={{
                    width: "100%",
                    zIndex: 0,
                    marginTop: "8px",
                  }}
                  options={agent.map((agent) => ({
                    value: agent.user_id,
                    label: agent.name,
                  }))}
                />
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="type">
                  Customer<span>*</span>
                </label>
                <Input
                  style={{ marginTop: "8px" }}
                  type="text"
                  id="subject"
                  name="group"
                  value={ticketData.group}
                  onChange={handleInputChange}
                />
              </div>
              {/* <Select
                  value={ticketData.group}
                  onChange={(value) => handleSelectChange("group", value)}
                  style={{
                    width: "100%",
                    marginTop: "8px",
                  }}
                  options={group.map((group) => ({
                    value: group.group_id,
                    label: group.group_name,
                  }))}
                /> */}
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="type">
                  Category <span>*</span>
                </label>
                <Select
                  value={ticketData.category}
                  onChange={(value) => handleSelectChange("category", value)}
                  style={{
                    width: "100%",
                    marginTop: "8px",
                  }}
                >
                  {category.map((option) => (
                    <Select.Option key={option.value} value={option.value}>
                      {option.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="type">
                  Email <span>*</span>
                </label>
                <Input
                  style={{
                    marginTop: "8px",
                  }}
                  type="email"
                  id="email"
                  name="email"
                  value={ticketData.email}
                  onChange={handleInputChange}
                />
                {emailError && (
                  <div className="error" style={{ color: "red" }}>
                    {emailError}
                  </div>
                )}
              </div>
            </Col>
            <Col xs={24} sm={12} md={8} lg={8} xl={8}>
              <div className="input">
                <label className="label" htmlFor="type">
                  Closed Date
                </label>
                <DatePicker
                  style={{
                    marginTop: "8px",
                  }}
                  placeholder="Select due date"
                  className="ticket-dueDate"
                  disabledDate={disabledDate}
                  onOpenChange={handleOpenChange}
                  type="text"
                  showTime
                  format={dateFormat}
                  id="ticket_close_date"
                  name="ticket_close_date"
                  value={
                    ticketData.ticket_close_date
                      ? dayjs(ticketData.ticket_close_date)
                      : null
                  }
                  onChange={handleDateChange}
                />
              </div>
            </Col>
          </Row>
          <div
            className="description"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label className="label" htmlFor="contractNumber">
              Description <span>*</span>
            </label>
            <textarea
              rows={10}
              columns={10}
              style={{
                marginTop: "8px",
                border: "1px solid #d9d9d9",
                borderRadius: "6px",
                ":focus": {
                  border: "1px solid grey",
                },
                padding: "10px",
              }}
              placeholder=""
              type="text"
              id="description"
              name="description"
              value={ticketData.description}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default Index;
