import React from "react";
import { useState, useEffect } from "react";
import { baseUrl } from "./../../utils/axios/index";
import axios from "axios";
import { Select } from "antd";
import { Option } from "antd/es/mentions";
import instance from "./../../utils/axios/index";
import { Link, useNavigate } from "react-router-dom";
import "react-tagsinput/react-tagsinput.css";

import "./addcontract.css";
import { Input, Button, DatePicker, Switch, message, Spin } from "antd";
import TagsInput from "react-tagsinput";

function Index() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [formData, setFormData] = useState({
    contractNumber: "",
    contract_name: "",
    vendor: "",
    add_vendor: "",
    type: "",
    status: "",
    visibles: "",
    approvers: "",
    cost: "0",
    auto_renew: "",
    notify_renewal: "",
    notify_to: [],
    notify_by: 0,
  });

  const navigate = useNavigate();

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const startDateValue = startDate ? new Date(startDate) : null;
    const endDateValue = endDate ? new Date(endDate) : null;

    try {
      // Form validation
      if (
        formData.contract_name === "" ||
        endDate === null ||
        startDate === null ||
        formData.type === "" ||
        formData.status === "" ||
        formData.visibles === "" ||
        formData.approvers === "" ||
        formData.cost === ""
      ) {
        message.error("Please fill in all required fields.");
        throw new Error("Please fill in all required fields.");
      } else if (endDateValue < startDateValue) {
        message.error("End date cannot be earlier than the start date.");
        throw new Error("End date cannot be earlier than the start date.");
      } else {
        // Additional data formatting or validation if needed
        formData.contract_name = formData.contract_name.toUpperCase();
        formData.auto_renew = renew;
        formData.notify_renewal = autoRenew;

        const response = await instance.post(`${baseUrl}/createcontract`, {
          contract_name: formData.contract_name,
          vendor: formData.vendor,
          add_vendor: formData.add_vendor,
          type: formData.type,
          status: formData.status,
          visible_to: formData.visibles,
          approver: formData.approvers,
          cost: formData.cost,
          start_date: startDate,
          end_date: endDate,
          auto_renew: formData.auto_renew,
          notify_renewal: formData.notify_renewal,
          notify_to: formData.notify_to,
          notify_by: formData.notify_by,
          attachment_id: attachmentid.attachment_id,
        });

        if (response.data) {
          message.success(response.data);
          setIsLoading(false);
          navigate("/contracts");
        }
      }
    } catch (error) {
      console.log("error=====>>===>", error);

      if (error.response) {
        message.error(error.response.data);
      }
      setIsLoading(false);
    }
  };

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "cost" && parseFloat(value) < 0) {
      return;
    }
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSelectChange = (key, value) => {
    setFormData((formData) => ({
      ...formData,
      [key]: value,
    }));
  };

  const [renew, setRenew] = useState(true);
  const [autoRenew, setAutoRenew] = useState(true);

  const [switchOn, setSwitchOn] = useState(false);

  const [vendor, setVendor] = useState([]);
  const [visible, setVisible] = useState([]);

  const [approver, setApprover] = useState([]);

  const type = [
    { value: "Lease", label: "Lease" },
    { value: "Warranty", label: "Warranty" },
    { value: "Maintenance", label: "Maintenance" },
    { value: "Software License", label: "Software License" },
    { value: "ManagedServices", label: "Managed Services" },
    { value: "amc", label: "Annual Maintenance Contract (AMC) " },
  ];

  const handleSwitchChange = (checked) => {
    setSwitchOn(checked);
    setAutoRenew(checked);
  };

  // date picker
  const onStartChange = (value, dateString) => {
    if (startDate === dateString) {
      // If the selected date is the same as the current date, clear it
      setStartDate(null);
    } else {
      // Otherwise, set the new date
      setStartDate(dateString);
    }
  };
  const onEndChange = (value, dateString) => {
    const endDate = new Date(dateString);
    const startDate = startDate ? new Date(startDate) : null;

    // Set hours, minutes, seconds, and milliseconds to 0 to compare only dates
    endDate.setHours(0, 0, 0, 0);
    if (startDate) {
      startDate.setHours(0, 0, 0, 0);
    }

    if (startDate && endDate < startDate) {
      message.error("End date cannot be earlier than start date.");
    } else {
      setEndDate(dateString);
    }
  };

  useEffect(() => {
    getVendor();
    getApprover();
    getVisible();
  }, []);

  const getVendor = async () => {
    try {
      const response = await instance.get(baseUrl + "/getallvendors");

      setVendor(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getApprover = async () => {
    try {
      const response = await instance.get(baseUrl + "/getallapprover");
      setApprover(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const getVisible = async () => {
    try {
      const response = await instance.get(baseUrl + "/getallvisible");
      setVisible(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const Status = [{ value: "Active", label: "Active" }];

  const [isToggleOn, setIsToggleOn] = useState(true);

  const handleChange = (checked) => {
    setIsToggleOn(checked);
    setRenew(checked);
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [attachmentInfo, setAttachmentInfo] = useState([]);
  const [attachmentid, setAttachmentid] = useState([]);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const handleUpload = async () => {
    try {
      setUploading(true);

      const formData = new FormData();
      for (let key in selectedFiles) {
        formData.append("attachment", selectedFiles[key]);
      }

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      const response = await axios.post(`${baseUrl}/attachmentFile`, formData, {
        headers,
      });

      if (response.status === 200) {
        if (Array.isArray(response.data) && response.data.length > 0) {
          setAttachmentInfo(response.data);
          setAttachmentid(response.data[0]);
          setSelectedFiles([]);
        } else {
          message.error(
            "Invalid document format. Please upload a valid document."
          );
        }
      } else {
        message.error("File upload failed");
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      message.error("File upload failed");
    } finally {
      setUploading(false);
    }
  };

  const handleTagChange = (emails) => {
    setFormData({
      ...formData,
      notify_to: emails,
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          width: "93vw",

          padding: "0px 0px 0px 20px",
          gap: "10px",
        }}
      >
        <Button
          className="save-btn"
          type="submit"
          style={{ backgroundColor: "#229849", color: "white" }}
          onClick={handleSubmitForm}
        >
          Create
        </Button>
        <Link to="/contracts">
          <Button className="cancel-btn" type="submit">
            Cancel
            {/* ??? */}
          </Button>
        </Link>
      </div>

      <form
        onSubmit={handleSubmitForm}
        style={{ width: "93vw", padding: "0px 0px 0px 20px" }}
      >
        <div className="first-container">
          <div className="heading-wrapper">
            <h2>General Details</h2>
          </div>
          <div className="input-wrapper">
            <div className="left-fields">
              {/* first input */}
              <div className="contract-input">
                <label className="label" htmlFor="contractNumber">
                  Contract Number <span>(Auto Generated)</span>
                </label>
                <Input
                  disabled={"true"}
                  placeholder="CNTR-"
                  type="text"
                  id="name"
                  name="name"
                  value={formData.contractNumber}
                  onChange={handleInputChange}
                />
              </div>
              {/* secound input */}
              <div className="contract-input">
                <label className="label" htmlFor="vendor">
                  Customer Name
                  <span>*</span>
                </label>
                <Select
                  className="select"
                  placeholder=""
                  value={formData.vendor}
                  onChange={(value) => handleSelectChange("vendor", value)}
                  style={{
                    width: "100%",
                  }}
                  options={vendor.map((vendor) => ({
                    value: vendor.vendor_id,
                    label: vendor.vendor_name,
                  }))}
                />
              </div>
              {/* Third Input */}
              <div className="contract-input">
                <label className="label" htmlFor="visibleTo">
                  Visible To
                  <span>*</span>
                </label>
                <Select
                  placeholder=""
                  value={formData.visibles}
                  onChange={(value) => handleSelectChange("visibles", value)}
                  style={{
                    width: "100%",
                  }}
                  options={visible.map((visible) => ({
                    value: visible.visible_id,
                    label: visible.visible_name,
                  }))}
                />
              </div>
            </div>
            <div className="right-fields">
              <article className="right-field-top-inputs">
                <div>
                  <label className="label" htmlFor="contractName">
                    Contract Name
                    <span>*</span>
                  </label>
                  <Input
                    type="text"
                    id="contract_name"
                    name="contract_name"
                    value={formData.contract_name}
                    onChange={handleInputChange}
                  />
                  {errors.contractName && (
                    <span className="error">{errors.contractName}</span>
                  )}
                </div>
                <div>
                  <label className="label" htmlFor="contractName">
                    Add Vendor
                    {/* <span>*</span> */}
                  </label>
                  <Input
                    type="text"
                    id="add_vendor"
                    name="add_vendor"
                    value={formData.add_vendor}
                    onChange={handleInputChange}
                  />
                  {errors.contractName && (
                    <span className="error">{errors.vendor}</span>
                  )}
                </div>
              </article>
              <div className="rightbottom-wrapper">
                <div className="rightbotton-first">
                  <div className="contract-input">
                    <label className="label" htmlFor="type">
                      Type
                      <span>*</span>
                    </label>
                    <Select
                      value={formData.type}
                      onChange={(value) => handleSelectChange("type", value)}
                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      {type.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="contract-input">
                    <label className="label" htmlFor="approver">
                      Approver
                      <span>*</span>
                    </label>
                    {isLoading && (
                      <Spin
                        tip="Submitting..."
                        size="large"
                        style={{
                          position: "absolute",
                          top: "40%",
                          left: "50%",
                          transform: "translate(50%, 50%)",
                          color: "white",
                        }}
                      />
                    )}
                    <Select
                      // mode="multiple"
                      placeholder=""
                      value={formData.approvers}
                      onChange={(label) =>
                        handleSelectChange("approvers", label)
                      }
                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                      options={approver.map((approver) => ({
                        value: approver.approver_id,
                        label: approver.approver_name,
                      }))}
                    />
                  </div>
                </div>
                <div className="rightbotton-secound">
                  <div className="contract-input">
                    <label className="label" htmlFor="status">
                      Status
                      <span>*</span>
                    </label>
                    <Select
                      // mode="multiple"
                      placeholder=""
                      value={formData.status}
                      onChange={(value) => handleSelectChange("status", value)}
                      style={{
                        width: "100%",
                        height: "40px",
                      }}
                    >
                      {Status.map((option) => (
                        <Option key={option.value} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className="contract-input">
                    <label className="label" htmlFor="cost">
                      Cost
                      <span>*</span>
                    </label>
                    <Input
                      type="number"
                      id="cost"
                      name="cost"
                      value={formData.cost}
                      onChange={handleInputChange}
                      required
                    />

                    {errors.cost && (
                      <span className="error">{errors.cost}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="first-container">
          <div className="heading-wrapper">
            <h2>Contract Tenure</h2>
          </div>
          <div className="date-wrapper">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                className="label"
                htmlFor="startDate"
                style={{ paddingBottom: "25px" }}
              >
                Start Date <span>*</span>
              </label>
              <DatePicker
                onChange={onStartChange}
                // value={startDate ? moment(startDate) : null}
                selected={startDate}
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                className="label"
                htmlFor="endDate"
                style={{ paddingBottom: "25px" }}
              >
                End Date <span>*</span>
              </label>
              <DatePicker
                onChange={onEndChange}
                // value={endDate ? moment(endDate) : null}
                selected={endDate}
              />
            </div>
          </div>
        </div>

        {/* Toggle button two */}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            margin: "30px 0px 0px 15px",
          }}
          className="toggle"
        >
          {/* <Switch
            defaultChecked
            checked={isToggleOn}
            onChange={handleChange}
            style={{
              backgroundColor: isToggleOn ? "#52c41a" : "gray",

              borderColor: isToggleOn ? "#52c41a" : "gray",
            }}
          /> */}
          <Switch
            checked={isToggleOn}
            onChange={handleChange}
            style={{
              backgroundColor: isToggleOn ? "#52c41a" : "gray",
              borderColor: isToggleOn ? "#52c41a" : "gray",
            }}
          />
          <h3>Auto Renew</h3>

          <span>
            {" "}
            (Contract will auto renew upon reaching contract expiry date)
          </span>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            margin: "15px 0px 0px 15px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              transition: "height 0.3s",
            }}
          >
            <Switch
              checked={switchOn}
              onChange={handleSwitchChange}
              style={{ backgroundColor: switchOn ? "#52c41a" : "gray" }}
            />
            <div
              style={{
                display: "flex",

                // flexDirection: "column",
              }}
              className="toggle"
            >
              <h3 className="sub-heading-toggle">Notify Expire</h3>

              <span>&ensp; &ensp;(Notify people upon expiry of contract)</span>
            </div>
          </div>
        </div>

        {switchOn && (
          <div
            className="input-container"
            style={{
              padding: "20px",
            }}
          >
            <div>
              <label className="label" htmlFor="contractNumber">
                Notify To <span>*</span>
              </label>

              <TagsInput
                style={{ width: "100px", border: "1px solid red" }}
                value={formData.notify_to}
                onChange={handleTagChange}
                inputProps={{
                  style: { width: "100%" },
                  placeholder: "Enter email",
                }}
              />
              {/* <Input
                placeholder="Email"
                type="text"
                id="notify_to"
                name="notify_to"
                value={formData.notify_to}
                onChange={handleInputChange}
              /> */}
            {/* <p>Days</p> */}
            </div>

            <div className="input-container">
              <div>
                <label className="label" htmlFor="contractNumber">
                  Notify Before <span>*</span>
                </label>
                <Input
                  type="number"
                  id="notify_by"
                  name="notify_by"
                  value={formData.notify_by}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        )}

        <div className="first-container">
          <div className="heading-wrapper">
            <h2>Associations</h2>
          </div>
        </div>

        <div style={{ padding: "20px" }}>
          <div
            style={{
              border: "1px dotted #229849",
              maxWidth: "30%",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "15px",
            }}
          >
            <input type="file" multiple onChange={handleFileChange} />
          </div>
          <button
            onClick={handleUpload}
            disabled={uploading || selectedFiles.length == 0}
            className="upload-doc-button"
            style={{
              border: "1px dotted #229849",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "5px 5px 5px 5px",
              color: "#fff",
              backgroundColor: "#229849",
              margin: "10px 0",
              padding: "10px",
            }}
          >
            Upload
          </button>

          {uploading && <p>Uploading...</p>}
          {attachmentInfo && attachmentInfo.length > 0 && (
            <div>
              <h3>Attachment Information</h3>
              <ul>
                {attachmentInfo.map((attachment, index) => (
                  <li key={index}>
                    Attachment Name: {attachment.attachement_name}, Attachment
                    ID: {attachment.attachment_id}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </form>
    </>
  );
}

export default Index;
